import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../events/events.css";
import moment from "moment";

// componetns
import BrandButton from "../button/BrandButton";
import Grid from "@material-ui/core/Grid";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Language";
import Chip from "@material-ui/core/Chip";
import DateRangeIcon from "@material-ui/icons/DateRange";

// utils
import convertTime from "../../utils/convertTime";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px !important",
    // borderBottom: "1px solid lightgray",
  },
  paragraph: {
    padding: "0px !important",
  },
}));

const HrsEventSummary = ({ item }) => {
  const classes = useStyles();
  const {
    eventName,
    eventStartDate,
    eventEndDate,
    imageUrl,
    isVirtual,
    location,
    registrationLink,
    timeZone,
  } = item;

  const handleClick = () => {
    window.open(`${registrationLink}`);
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <div className="event-container">
        {/* filters  */}

        {/* filters  */}
        <div className="event-item">
          <div
            className="event-item__left event-hrs-item-bg"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          >
            <div className="event-bg-overlay"></div>
          </div>
          <div className="event-item__right">
            <div className="event-item__right-info">
              <div className="event-item__right-info-inner">
                <div className="event-item__type">
                  <div className="event-item__title primary-color">
                    {eventName}
                  </div>

                  <div className="event-dates">
                    <Chip
                      icon={<DateRangeIcon />}
                      label={
                        moment(eventStartDate).format("YYYY-MM-DD") ===
                        moment(eventEndDate).format("YYYY-MM-DD")
                          ? moment(eventStartDate).format("LL")
                          : `${moment(eventStartDate).format("LL")} - ${moment(
                              eventEndDate
                            ).format("LL")}`
                      }
                    />
                  </div>
                  <div className="event_location_time">
                    <Chip
                      icon={<ScheduleIcon />}
                      label={`${convertTime(
                        moment(eventStartDate).format("HH:mm")
                      )} - ${convertTime(moment(eventEndDate).format("HH:mm"))}
                      ${timeZone}`}
                    />{" "}
                    <Chip
                      icon={isVirtual ? <LanguageIcon /> : <RoomIcon />}
                      label={`${isVirtual ? "Online" : location}`}
                    />
                  </div>
                </div>
              </div>
              <div className="event-buttons">
                <div className="toggle-buttons">
                  <BrandButton
                    title="LEARN MORE"
                    bgColorPrimary={true}
                    onClick={() => handleClick()}
                    style={{ marginRight: 20 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default HrsEventSummary;
