import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../config/fbConfig";
// components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Swal from "sweetalert2";
import moment from "moment";
import { popupAlert } from "../../utils/popupAlert";
import tConvert from "../../utils/convertTime";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const HrsAdminEventList = ({
  eventData,
  handleDelete,
  handleAdd,
  handleToggle,
  handleToggleReg,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Event Image</TableCell>
            <TableCell>Event Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Registration Link</TableCell>

            <TableCell align="center">Enable/Disable</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventData.map((row) => (
            <TableRow
              key={row.id}
              style={{
                borderLeft: row.isActive
                  ? "15px solid green"
                  : "15px solid #d33",
              }}
            >
              <TableCell>
                <img
                  src={row.imageUrl}
                  alt="Event"
                  width="100px"
                  height="50px"
                />
              </TableCell>
              <TableCell>{row.eventName}</TableCell>
              <TableCell>
                {moment(row.eventStartDate).format("LL")}{" "}
                {moment(row.eventStartDate).format("LL") ===
                moment(row.eventEndDate).format("LL")
                  ? ""
                  : `- ${moment(row.eventEndDate).format("LL")}`}
              </TableCell>
              <TableCell>{!row.isVirtual ? row.location : "Online"}</TableCell>
              <TableCell>
                {tConvert(moment(row.eventStartDate).format("HH:mm"))} -{" "}
                {tConvert(moment(row.eventEndDate).format("HH:mm"))}{" "}
                {row.timeZone}
              </TableCell>
              <TableCell>
                <a href={row.registrationLink} target="_blank" rel="noreferrer">
                  {row.registrationLink}
                </a>
              </TableCell>
              <TableCell align="center">
                {row.isActive ? (
                  <>
                    <IconButton
                      onClick={() =>
                        handleToggle(
                          row.id,
                          row.eventName,
                          row.eventStartDate,
                          row.isActive
                        )
                      }
                    >
                      <CheckIcon style={{ color: "green" }} />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      onClick={() =>
                        handleToggle(
                          row.id,
                          row.eventName,
                          row.eventStartDate,
                          row.isActive
                        )
                      }
                    >
                      <BlockIcon style={{ color: "#d33" }} />
                    </IconButton>
                  </>
                )}
              </TableCell>

              <TableCell align="center">
                <IconButton
                  onClick={() =>
                    handleDelete(row.id, row.eventName, row.eventStartDate)
                  }
                >
                  <DeleteIcon style={{ color: "#d33" }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HrsAdminEventList;
