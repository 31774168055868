export const getProductUrl = (name) => {
  switch (name) {
    case "PDM: Administering SOLIDWORKS PDM Standard Course":
      return "administering-solidworks-pdm-standard-course";

    case "PDM: Administering SOLIDWORKS PDM Professional Course":
      return "administering-solidworks-pdm-professional-course";

    case "CAM: CAM Standard: 2.5 Axis Mill Course":
      return "cam-standard-2-5-axis-mill-course";

    case "CAM: CAMWorks 3 Axis Mill Course":
      return "cam-standard-3-axis-mill-course";

    case "CAM: CAM Professional: Advanced Functionality and Turning Course":
      return "cam-professional-advanced-functionality-and-turning-course";

    case "CAM: CAMWorks Multi-Axis (4 and 5 Axis) Mill Course":
      return "camworks-multi-axis-4-and-5-axis-mill-course";

    case "CAM: CAMWorks Multi-Task Mill-Turn Course":
      return "camworks-multi-task-mill-turn-course";

    case "DRIVEW: DriveWorks  Essentials Course":
      return "driveworks-essentials-course";

    case "DRIVEW: DriveWorks  Advanced Course":
      return "driveworks-advanced-course";

    case "SWX: SOLIDWORKS Essentials Course":
      return "solidworks-essentials-course";

    case "SWX: SOLIDWORKS Drawings Course":
      return "solidworks-drawings-course";

    case "SWX: SOLIDWORKS Assembly Modeling Course":
      return "solidworks-assembly-modeling-course";

    case "SWX: SOLIDWORKS Surface Modeling Course":
      return "solidworks-surface-modeling-course";

    case "SWX: SOLIDWORKS Advanced Part Modeling Course":
      return "solidworks-advanced-part-modeling-course";

    case "SWX: SOLIDWORKS File Management Course":
      return "solidworks-file-management-course";

    case "SWX: SOLIDWORKS Weldments Course":
      return "solidworks-weldments-course";

    case "SWX: SOLIDWORKS Sheet Metal Course":
      return "solidworks-sheet-metal-course";

    case "SWX: SOLIDWORKS Mold Design Essentials Course":
      return "solidworks-mold-design-essentials-course";

    case "SWX: SOLIDWORKS Routing: Electrical Course":
      return "solidworks-routing-electrical-course";

    case "SWX: SOLIDWORKS Routing: Piping and Tubing Course":
      return "solidworks-routing-piping-and-tubing-course";

    case "SIM: SOLIDWORKS Simulation Course":
      return "solidworks-simulation-course";

    case "SIM: SOLIDWORKS Simulation Premium: Nonlinear Course":
      return "solidworks-simulation-premium-nonlinear-course";

    case "SIM: SOLIDWORKS Flow Simulation Course":
      return "solidworks-flow-simulation-course";

    case "SIM: SOLIDWORKS Simulation Professional Course":
      return "solidworks-simulation-professional-course";

    case "SIM: SOLIDWORKS Simulation Premium: Dynamics Course":
      return "solidworks-simulation-premium-dynamics-course";

    case "SIM: SOLIDWORKS Plastics: Part Validation Course":
      return "solidworks-plastics-part-validation-course";

    case "SIM: SOLIDWORKS Plastics: Mold Validation Course":
      return "solidworks-plastics-mold-validation-course";

    case "SIM: SOLIDWORKS Flow Simulation: Rotating Machinery Course":
      return "solidworks-flow-simulation-rotating-machinery-course";

    case "SIM: SOLIDWORKS Flow Simulation: Electronics Cooling Course":
      return "solidworks-flow-simulation-electronics-cooling-course";

    case "SWTECH: SOLIDWORKS Visualize Course":
      return "solidworks-visualize-course";

    case "SWTECH: SOLIDWORKS Inspection Course":
      return "solidworks-inspection-course";

    case "SWTECH: SOLIDWORKS Composer Course":
      return "solidworks-composer-course";

    case "ELEC: SOLIDWORKS Electrical 3D Course":
      return "solidworks-electrical-3d-course";

    case "ELEC: SOLIDWORKS Electrical Advanced Course":
      return "solidworks-electrical-advanced-course";

    case "ELEC: SOLIDWORKS Electrical Schematic Course":
      return "solidworks-electrical-schematic-course";

    case "PDM: Using SOLIDWORKS PDM Course":
      return "using-solidworks-pdm-course";

    default:
      return null;
  }
};

export const getProductNameFromUrl = (selectedCategory) => {
  switch (selectedCategory) {
    case "administering-solidworks-pdm-standard-course":
      return "PDM: Administering SOLIDWORKS PDM Standard Course";

    case "administering-solidworks-pdm-professional-course":
      return "PDM: Administering SOLIDWORKS PDM Professional Course";

    case "cam-standard-2-5-axis-mill-course":
      return "CAM: CAM Standard: 2.5 Axis Mill Course";

    case "camworks-3-axis-mill-course":
      return "CAM: CAMWorks 3 Axis Mill Course";

    case "cam-professional-advanced-functionality-and-turning-course":
      return "CAM: CAM Professional: Advanced Functionality and Turning Course";

    case "camworks-multi-axis-4-and-5-axis-mill-course":
      return "CAM: CAMWorks Multi-Axis (4 and 5 Axis) Mill Course";

    case "camworks-multi-task-mill-turn-course":
      return "CAM: CAMWorks Multi-Task Mill-Turn Course";

    case "driveworks-essentials-course":
      return "DRIVEW: DriveWorks  Essentials Course";

    case "driveworks-advanced-course":
      return "DRIVEW: DriveWorks  Advanced Course";

    case "solidworks-essentials-course":
      return "SWX: SOLIDWORKS Essentials Course";

    case "solidworks-drawings-course":
      return "SWX: SOLIDWORKS Drawings Course";

    case "solidworks-assembly-modeling-course":
      return "SWX: SOLIDWORKS Assembly Modeling Course";

    case "solidworks-surface-modeling-course":
      return "SWX: SOLIDWORKS Surface Modeling Course";

    case "solidworks-advanced-part-modeling-course":
      return "SWX: SOLIDWORKS Advanced Part Modeling Course";

    case "solidworks-file-management-course":
      return "SWX: SOLIDWORKS File Management Course";

    case "solidworks-weldments-course":
      return "SWX: SOLIDWORKS Weldments Course";

    case "solidworks-sheet-metal-course":
      return "SWX: SOLIDWORKS Sheet Metal Course";

    case "solidworks-mold-design-essentials-course":
      return "SWX: SOLIDWORKS Mold Design Essentials Course";

    case "solidworks-routing-electrical-course":
      return "SWX: SOLIDWORKS Routing: Electrical Course";

    case "solidworks-routing-piping-and-tubing-course":
      return "SWX: SOLIDWORKS Routing: Piping and Tubing Course";

    case "solidworks-simulation-course":
      return "SIM: SOLIDWORKS Simulation Course";

    case "solidworks-simulation-premium-nonlinear-course":
      return "SIM: SOLIDWORKS Simulation Premium: Nonlinear Course";

    case "solidworks-flow-simulation-course":
      return "SIM: SOLIDWORKS Flow Simulation Course";

    case "solidworks-simulation-professional-course":
      return "SIM: SOLIDWORKS Simulation Professional Course";

    case "solidworks-simulation-premium-dynamics-course":
      return "SIM: SOLIDWORKS Simulation Premium: Dynamics Course";

    case "solidworks-plastics-part-validation-course":
      return "SIM: SOLIDWORKS Plastics: Part Validation Course";

    case "solidworks-plastics-mold-validation-course":
      return "SIM: SOLIDWORKS Plastics: Mold Validation Course";

    case "solidworks-flow-simulation-rotating-machinery-course":
      return "SIM: SOLIDWORKS Flow Simulation: Rotating Machinery Course";

    case "solidworks-flow-simulation-electronics-cooling-course":
      return "SIM: SOLIDWORKS Flow Simulation: Electronics Cooling Course";

    case "solidworks-visualize-course":
      return "SWTECH: SOLIDWORKS Visualize Course";

    case "solidworks-inspection-course":
      return "SWTECH: SOLIDWORKS Inspection Course";

    case "solidworks-composer-course":
      return "SWTECH: SOLIDWORKS Composer Course";

    case "solidworks-electrical-3d-course":
      return "ELEC: SOLIDWORKS Electrical 3D Course";

    case "solidworks-electrical-advanced-course":
      return "ELEC: SOLIDWORKS Electrical Advanced Course";

    case "solidworks-electrical-schematic-course":
      return "ELEC: SOLIDWORKS Electrical Schematic Course";

    case "using-solidworks-pdm-course":
      return "PDM: Using SOLIDWORKS PDM Course";

    default:
      return null;
  }
};
