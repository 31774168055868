import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../config/fbConfig";
import "./events.css";

// components
import Grid from "@material-ui/core/Grid";
import EventSummary from "./EventSummary";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BrandButton from "../button/BrandButton";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from "../skeleton/Skeleton";

// utils
import quickSort from "../../utils/quickSort";
import { popupAlert } from "../../utils/popupAlert";
import moment from "moment";
import { nextYear, oneWeekDate } from "../../utils/dateManipulate";
import { setWithExpiry, getWithExpiry } from "../../utils/localStorageUtils";
import {
  mapMonth,
  classesObjectArrayNameOnly,
} from "../../utils/constArraydata";
import { getProductUrl } from "../../utils/getProductUrl";

const useStyles = makeStyles((theme) => ({
  eventListContainer: {
    paddingTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlName: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EventList = ({ category, disableSelectCourse, currentYear }) => {
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format("MMMM").toLowerCase()
  );
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClassArray, setSelectedClassArray] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState(category);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    if (disableSelectCourse) {
      loadIndividualEventData(category, currentYear);
    } else {
      loadEventsData(selectedClassName, selectedMonth, selectedYear);
    }
  }, []);

  const loadIndividualEventData = async (cat, y) => {
    setIsLoading(true);
    let eventData = [];

    try {
      let getLocalEventsData = await getWithExpiry(
        `${y}-${getProductUrl(cat)}`
      );

      if (getLocalEventsData) {
        // console.log("Loading from local storage");
        setNoData(false);
        setEvents(getLocalEventsData);
      } else {
        let data = await firebase
          .firestore()
          .collection(`${y}-events`)
          .where("custrecord_te_class_name", "==", `${cat}`)
          .get();

        data.forEach((doc) => {
          if (
            doc.exists &&
            doc.data().isInactive === false &&
            doc.data().custrecord_te_closed_class === false &&
            moment(doc.data().custrecord_te_start_date).format("YYYY-MM-DD") >
              oneWeekDate() &&
            (doc.data().custrecord_te_status === "Scheduled" ||
              doc.data().custrecord_te_status === "Confirmed") &&
            (doc.data().custrecord_te_class_type === "Online" ||
              doc.data().custrecord_te_class_type === "Classroom")
          ) {
            eventData.push({ id: doc.id, ...doc.data() });
          }
        });

        if (eventData.length !== 0) {
          setEvents(quickSort(eventData, "custrecord_te_start_date", "desc"));
          // setEvents
          setWithExpiry(
            `${y}-${getProductUrl(cat)}`,
            quickSort(eventData, "custrecord_te_start_date", "desc")
          );
          setNoData(false);
        } else {
          setWithExpiry(`${y}-${getProductUrl(cat)}`, null);
          setEvents([]);
          setNoData(true);
        }
      }
    } catch (error) {
      popupAlert("error", "Error", "Please try again later");
      setNoData(true);
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadEventsData = async (cat, m, y) => {
    setIsLoading(true);
    try {
      let getLocalYearMonthAllData = await getWithExpiry(`${y}-${m}-${cat}`);

      if (getLocalYearMonthAllData) {
        // console.log("Loading from local storage");
        setNoData(false);
        setEvents(getLocalYearMonthAllData);
      } else {
        let responseYearData = await firebase
          .firestore()
          .collection(y)
          .doc(m)
          .get();

        // console.log(responseYearData);
        let eventIdArray = [];
        let eventData = [];

        if (responseYearData.exists) {
          let returnDocumentEvents = responseYearData.data().events;
          returnDocumentEvents.map((event) => {
            // !GET ALL
            if (
              cat === "All" &&
              event.isInactive === false &&
              event.custrecord_te_closed_class === false &&
              moment(event.custrecord_te_start_date).format("YYYY-MM-DD") >
                oneWeekDate() &&
              (event.custrecord_te_status === "Scheduled" ||
                event.custrecord_te_status === "Confirmed") &&
              (event.custrecord_te_class_type === "Online" ||
                event.custrecord_te_class_type === "Classroom")
            ) {
              // push filtered data to array

              eventIdArray.push({ id: event.id });
            }

            // TODO: SOME RECORD IS MISSING ON ALL
            if (
              cat.includes(event.custrecord_te_class_name) &&
              event.isInactive === false &&
              event.custrecord_te_closed_class === false &&
              moment(event.custrecord_te_start_date).format("YYYY-MM-DD") >
                oneWeekDate() &&
              (event.custrecord_te_status === "Scheduled" ||
                event.custrecord_te_status === "Confirmed") &&
              (event.custrecord_te_class_type === "Online" ||
                event.custrecord_te_class_type === "Classroom")
            ) {
              eventIdArray.push({ id: event.id });
            }
            return null;
          });

          // check after filters
          if (eventIdArray.length !== 0) {
            let getFilteredEventData = await Promise.all(
              eventIdArray.map(async (item) => {
                let querySnapShot = await firebase
                  .firestore()
                  .collection(`${y}-events`)
                  .doc(item.id)
                  .get();

                eventData.push({
                  id: querySnapShot.id,
                  ...querySnapShot.data(),
                });

                return null;
              })
            );

            // if cat = "All", then store in local
            if (cat === "All") {
              setWithExpiry(
                `${y}-${m}-${cat}`,
                quickSort(eventData, "custrecord_te_start_date", "desc")
              );
            }

            // push to use state event
            setEvents(quickSort(eventData, "custrecord_te_start_date", "desc"));
            setNoData(false);
          } else {
            // if all event has been filtered out set no data to true
            setEvents([]);
            setNoData(true);
          }
        } else {
          // if no data exist at the time of filer
          setNoData(true);
        }
      }

      // end of try
    } catch (error) {
      popupAlert("error", "Error", "Please try again later");
      setNoData(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedClassArray(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmitIndividual = () => {
    loadIndividualEventData(category, selectedYear);
  };

  const handleSubmit = () => {
    if (selectedClassArray.length === 0) {
      loadEventsData(selectedClassName, selectedMonth, selectedYear);
    } else {
      loadEventsData(selectedClassArray, selectedMonth, selectedYear);
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Grid container spacing={3} className={classes.eventListContainer}>
      {/* Filter goes here  */}

      <Grid item xs={12}>
        <div className="event-filters">
          {disableSelectCourse ? (
            ""
          ) : (
            <>
              <FormControl
                variant="outlined"
                className={classes.formControlName}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {selectedClassArray.length ? "Selected Course(s)" : "All"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedClassArray}
                  onChange={handleChange}
                  label="Course Name"
                  multiple
                  renderValue={(selected) => selected.join(", ")}
                >
                  {selectedClassArray.length ? (
                    ""
                  ) : (
                    <MenuItem value="All" disabled>
                      <Checkbox checked="All" />
                      <ListItemText primary="ALL" />
                    </MenuItem>
                  )}
                  {classesObjectArrayNameOnly.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={selectedClassArray.indexOf(name) > -1}
                        labelStyle={{ color: "white" }}
                        iconStyle={{ fill: "blue" }}
                      />
                      <ListItemText
                        primary={name.substr(name.indexOf(":") + 1)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Month
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  label="Month"
                >
                  <MenuItem value={selectedMonth} disabled>
                    {selectedMonth.toUpperCase()}
                  </MenuItem>

                  {mapMonth.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {/* year  */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedYear}
              onChange={handleYearChange}
              label="Month"
            >
              <MenuItem value={selectedYear} disabled>
                {selectedYear}
              </MenuItem>
              <MenuItem value={moment().format("YYYY").toString()}>
                {moment().format("YYYY").toString()}
              </MenuItem>
              <MenuItem value={nextYear}>{nextYear}</MenuItem>
            </Select>
            {/* <FormHelperText>Selected Year: {selectedYear}</FormHelperText> */}
          </FormControl>
          <BrandButton
            title="Apply Filters"
            bgColorPrimary={true}
            onClick={
              disableSelectCourse ? handleSubmitIndividual : handleSubmit
            }
          />
        </div>
      </Grid>

      {noData ? (
        <>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <p style={{ padding: 20 }}>
                Sorry, no courses, please adjust your filters or{" "}
                <a href="/contact-us" style={{ color: "#2188c9" }}>
                  contact us
                </a>{" "}
                to request a class.
              </p>
            </Paper>
          </Grid>
        </>
      ) : (
        <>
          {events.map((item, index) => (
            <EventSummary
              key={index}
              item={item}
              disableSelectCourse={disableSelectCourse}
            />
          ))}
        </>
      )}
    </Grid>
  );
};

export default EventList;
