import * as Yup from "yup";

export const userSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

export const updateEventSchema = Yup.object({
  eventId: Yup.number("Enter Event Id").required("Event ID is required"),
});

export const addHrsEventSchema = Yup.object({
  eventName: Yup.string().required("Event Name is required"),
  imageUrl: Yup.string().required("Image Url is required"),
  registrationLink: Yup.string().required("Registration Link is required"),
  timeZone: Yup.string().required("Timezone is required"),
  eventStartDateAndTime: Yup.string().required(
    "Start Date and Time are required"
  ),
  eventEndDateAndTime: Yup.string().required("End Date and Time are required"),
  location: Yup.string().optional(),
});
