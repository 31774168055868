import { createSlice } from "@reduxjs/toolkit";
import { popupAlert } from "../../utils/popupAlert";

const initialState = {
  isLogin: false,
  isAuthenitcate: false,
  refreshToken: null,
  uid: null,
  email: "",
  password: "",
};

export const slice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        uid: action.payload.uid,
        email: action.payload.email,
        isLogin: action.payload.login,
        refreshToken: action.payload.refreshToken,
      };
    },
    logout: (state, action) => ({
      ...state,
      isLogin: false,
    }),
  },
});

export const loginSlice = slice.actions;

export const isLoggedIn =
  (uid, email, login, refreshToken) => async (dispatch) => {
    try {
      dispatch(
        slice.actions.login({
          uid,
          email,
          login,
          refreshToken,
        })
      );
    } catch (error) {
      popupAlert("error", "Error", error.message);
    }
  };

export const logout = () => async (dispatch) => {
  try {
    dispatch(slice.actions.logout());
  } catch (error) {}
};

export default slice.reducer;
