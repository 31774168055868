import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    margin: "0px !important",
    padding: "0px !important",
  },
}));
const EventAdminSummary = ({ item }) => {
  const classes = useStyles();
  const {
    custrecord_te_class_name,
    custrecord_te_class_sku,
    custrecord_te_classroom,
    custrecord_te_closed_class,
    custrecord_te_confstudents,
    custrecord_te_maxstudents,
    custrecord_te_custom_detail,
    custrecord_te_status,
    custrecord_te_website_notes,
    custrecord_te_waitstudents,
    custrecord_te_regstudents,

    month,
    year,
    name,
  } = item;

  return (
    <div className="event-admin-view">
      <div className="event-item__text" style={{ display: "block" }}>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Name:</span>
          {name}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>
            NetSuite Class Name:
          </span>
          {custrecord_te_class_name}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>
            Private/Tailored?
          </span>
          {custrecord_te_custom_detail
            ? custrecord_te_custom_detail
            : "NOT PRIVATE OR TAILORED"}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>
            Website Note:
          </span>
          {custrecord_te_website_notes
            ? custrecord_te_website_notes
            : "No website notes"}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Class SKU:</span>
          {custrecord_te_class_sku}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Classroom:</span>
          {custrecord_te_classroom}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Class Close:</span>
          {custrecord_te_closed_class ? "No" : "Yes"}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>
            Confirm Students:
          </span>
          {custrecord_te_confstudents}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>
            Register Student:
          </span>
          {custrecord_te_regstudents}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Wait List:</span>
          {custrecord_te_waitstudents}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Max Student:</span>
          {custrecord_te_maxstudents}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>
            Class Status:
          </span>
          {custrecord_te_status}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Month:</span>
          {month}
        </p>
        <p className={classes.paragraph}>
          <span style={{ color: "#2189c9", marginRight: 5 }}>Year:</span>
          {year}
        </p>
      </div>
    </div>
  );
};

export default EventAdminSummary;
