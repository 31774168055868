import moment from "moment";
export const nextYear = moment(moment().add(1, "years")._d)
  .format("YYYY")
  .toString();

export const oneWeekDate = () => {
  switch (moment().day()) {
    case 0:
      return moment(moment().add(14, "d")._d).format("YYYY-MM-DD");
    case 1:
      return moment(moment().add(13, "d")._d).format("YYYY-MM-DD");
    case 2:
      return moment(moment().add(12, "d")._d).format("YYYY-MM-DD");
    case 3:
      return moment(moment().add(11, "d")._d).format("YYYY-MM-DD");
    case 4:
      return moment(moment().add(10, "d")._d).format("YYYY-MM-DD");
    case 5:
      return moment(moment().add(9, "d")._d).format("YYYY-MM-DD");
    case 6:
      return moment(moment().add(15, "d")._d).format("YYYY-MM-DD");
    default:
      return moment(moment().add(10, "d")._d).format("YYYY-MM-DD");
  }
};

// export const oneWeekDate = moment(moment().add(10, "d")._d).format(
//   "YYYY-MM-DD"
// );

export const getEventYearToString = (eventDate) => {
  return moment(eventDate).format("YYYY").toString();
};

export const getEventMonthToLowerString = (eventDate) => {
  return moment(eventDate).format("MMMM").toLowerCase();
};
