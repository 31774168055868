import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// componetns
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import logo from "../../assets/hawk-ridge-systems-logo-1.png";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    marginRight: 20,
  },
  navBar: {
    background: "#2c88c9",
  },
  toolBar: {
    display: "unset",
    paddingTop: 5,
  },
  menuLink: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Navbar = ({ isUserLoggedIn }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.navBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.menuLink}>
            <img src={logo} alt="logo" width="150" height="50" />
            {isUserLoggedIn ? <SignedInLinks /> : <SignedOutLinks />}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
