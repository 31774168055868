import React, { useEffect, useState } from "react";
import firebase from "../../config/fbConfig";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// componetns
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { popupAlert } from "../../utils/popupAlert";
import Paper from "@material-ui/core/Paper";
import IndividualEvent from "./IndividualEvent";
import { Container } from "@material-ui/core";

import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import WebIcon from "@material-ui/icons/Web";

// assets
import signInLogo from "../../assets/sign-in-logo.png";

// utils
import { oneWeekDate } from "../../utils/dateManipulate";
import { getWithExpiry, setWithExpiry } from "../../utils/localStorageUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: 20,
    // paddingBottom: 20,
    // height: "100vh",
    // marginTop: "10vh",
    // marginBottom: "10vh",
  },
  paper: {
    overflow: "hidden",
  },
  image: {
    padding: 20,
  },
}));

const EventDetails = (props) => {
  const classes = useStyles();
  const paramsId = props.match.params.id;
  const paramsYear = props.match.params.year;
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [doesEventExist, setDoesEventExist] = useState(false);

  useEffect(() => {
    loadEvent();
  }, []);

  const loadEvent = async () => {
    // on load, load individual event

    try {
      // console.log("Load from db");
      // goes back here

      let eventYearAndId = `${paramsYear}-${paramsId}`;

      let getLocalData = await getWithExpiry(eventYearAndId);

      if (getLocalData) {
        // console.log("Loading from local");

        setEvent(getLocalData);
      } else {
        let res = await firebase
          .firestore()
          .collection(`${paramsYear}-events`)
          .doc(paramsId)
          .get();

        if (
          res.exists &&
          moment(res.data().custrecord_te_start_date).format("YYYY-MM-DD") >
            oneWeekDate() &&
          res.data().isInactive === false &&
          res.data().custrecord_te_closed_class === false &&
          (res.data().custrecord_te_status === "Scheduled" ||
            res.data().custrecord_te_status === "Confirmed")
        ) {
          setEvent(res.data());
          // console.log(res.data());
          setWithExpiry(eventYearAndId, res.data());
        } else {
          setDoesEventExist(true);
          popupAlert("error", "Error", "No event found");
        }
      }
    } catch (error) {
      //
      popupAlert(
        "error",
        "Error",
        "Unable to get event, please contact your sales rep or training@hawkridgesys.com "
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="lg" className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <Skeleton variant="rect" width="100%" animation="wave">
            <div style={{ paddingTop: "500px" }} />
          </Skeleton>
        </Paper>
      </Container>
    );
  }

  if (doesEventExist) {
    return (
      <Container maxWidth="lg" className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <div className="individual-container">
            <div className="individual-inner">
              <div className="individual-event__info">
                <div className="individual-event__content">
                  <div className="individual-logo">
                    <img src={signInLogo} alt="Hawk Ridge Systems" />
                  </div>
                  <p>
                    Sorry, this class is no longer available, please{" "}
                    <a style={{ color: "#2189c9" }} href="/contact-us">
                      contact us
                    </a>{" "}
                    to request a class.
                  </p>
                </div>
              </div>
              {/* end invent info  */}
              <div
                className="individual-contact"
                style={{ background: "#2189c9" }}
              >
                <div className="individual-contact__inner">
                  <div className="individual-contact__content">
                    <div className="individual-details color-white">
                      <div className="individual-contact__title">
                        <h2>Contact Us</h2>
                      </div>
                      <div className="individual-contact__email individual-flex">
                        <div className="individual-icon">
                          <MailOutlineIcon />
                        </div>
                        <div>
                          <p>
                            <strong>Training:</strong>{" "}
                            <a href="mailto:training@hawkridgesys.com">
                              training@hawkridgesys.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="individual-contact__phone individual-flex">
                        <div className="individual-icon">
                          <PhoneIphoneIcon />
                        </div>
                        <div>
                          <p>
                            <strong>US:</strong>{" "}
                            <a href="tel:8772664469">877.266.4469</a>
                          </p>
                          <p>
                            <strong>Canada:</strong>{" "}
                            <a href="tel:8665876803">866.587.6803</a>
                          </p>
                        </div>
                      </div>
                      <div className="individual-contact__email individual-flex">
                        <div className="individual-icon">
                          <WebIcon />
                        </div>
                        <div>
                          <p>
                            <strong>Website:</strong>{" "}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://hawkridgesys.com"
                            >
                              hawkridgesys.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end individual contact  */}
            </div>
          </div>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid>
        <IndividualEvent item={event} />
      </Grid>
    </Container>
  );
};

export default EventDetails;
