import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  eventListContainer: {
    paddingTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlName: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EventSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.eventListContainer}>
      <Grid item xs={12}>
        <div className="event-container">
          <div className="event-item">
            <div className="event-item__left">
              <Skeleton
                variant="rect"
                width="100%"
                height={225}
                animation="wave"
              ></Skeleton>
            </div>
            <div className="event-item__right">
              <div className="event-item__right-info">
                <div className="event-item__right-info-inner">
                  <div className="event-item__type">
                    <div className="event-item__title">
                      <Skeleton
                        // variant="rect"
                        width={350}
                        height={40}
                        animation="wave"
                      ></Skeleton>
                    </div>
                    <div className="event-item__text">
                      <Skeleton
                        // variant="rect"
                        width={100}
                        height={40}
                        animation="wave"
                      ></Skeleton>
                    </div>
                    <div className="event-dates">
                      <Skeleton
                        // variant="rect"
                        width={250}
                        height={40}
                        animation="wave"
                      ></Skeleton>
                    </div>
                    <div className="event_location_time">
                      <Skeleton
                        // variant="rect"
                        width={300}
                        height={40}
                        animation="wave"
                      ></Skeleton>

                      <div className="spots_remain">
                        <span className="event-info-rsvp-top">
                          <span className="event-info-rsvp-remaining">
                            <Skeleton
                              // variant="rect"
                              width={150}
                              height={40}
                              animation="wave"
                            ></Skeleton>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default EventSkeleton;
