import { combineReducers, configureStore, compose } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import loginReducer from "./redux/reducers/loginReducers";
import loaderReducer from "./redux/reducers/loaderReducers";

const rootReducer = combineReducers({
  login: loginReducer,
  loader: loaderReducer,
});

const middleware = [thunk];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  composeEnhancers,
});

export default store;
