import React, { useState } from "react";
import firebase from "../../config/fbConfig";
import axios from "axios";

// compnents
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import AdminBrandButton from "../button/AdminBrandButton";
import Paper from "@material-ui/core/Paper";

//utils
import { popupAlert } from "../../utils/popupAlert";
import moment from "moment";
import { mapMonth } from "../../utils/constArraydata";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  eventListContainer: {
    paddingTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ImportEvents = () => {
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format("MMMM").toLowerCase()
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  let nextYear = moment(moment().add(1, "years")._d).format("YYYY").toString();
  const [eventsData, setEventsData] = useState([]);
  let todaysDate = moment().format("LL");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {

  // }, []);

  const getData = async (lastNumMonth, mm, yy, monthToImport, yearToImport) => {
    setIsLoading(true);

    const data = {
      dateFrom: `${mm}/01/${yy}`,
      dateTo: `${mm}/${lastNumMonth}/${yy}`,
    };

    var config = {
      method: "get",
      url: "https://us-central1-hrs-events-5a2ea.cloudfunctions.net/app/api/v1/import-events",

      // data : data
      params: data,
    };

    try {
      console.log(config);
      let response = await axios(config);

      let eventList = [];

      let returnData = response.data;

      if (response.data.error) {
        popupAlert(
          "error",
          "error",
          `${response.data.error} - Code: ${response.data.code}`
        );
      } else {
        let saveData = await Promise.all(
          returnData.map(async (data) => {
            if (data.isInactive === false) {
              eventList.push({
                id: data.id,
                custrecord_te_class_name: data.custrecord_te_class_name.refName,
                custrecord_te_status: data.custrecord_te_status.refName,
                isInactive: data.isInactive,
                custrecord_te_closed_class: data.custrecord_te_closed_class,
                custrecord_te_start_date: data.custrecord_te_start_date,
                custrecord_te_class_type: data.custrecord_te_class_type.refName,
              });
              // insert into individual
              let insertIndividual = await firebase
                .firestore()
                .collection(`${yearToImport}-events`)
                .doc(data.id)
                .set({
                  custrecord_te_class_name:
                    data.custrecord_te_class_name.refName,
                  custrecord_te_class_sku: data.custrecord_te_class_sku.refName,
                  custrecord_te_class_type:
                    data.custrecord_te_class_type.refName,
                  custrecord_te_classroom: data.custrecord_te_classroom.refName,
                  custrecord_te_closed_class: data.custrecord_te_closed_class,
                  custrecord_te_confstudents: data.custrecord_te_confstudents,
                  custrecord_te_end_date: data.custrecord_te_end_date,
                  custrecord_te_end_time: data.custrecord_te_end_time,
                  custrecord_te_location: data.custrecord_te_location.refName,
                  custrecord_te_maxstudents: data.custrecord_te_maxstudents,
                  custrecord_te_regstudents: data.custrecord_te_regstudents,
                  custrecord_te_start_date: data.custrecord_te_start_date,
                  custrecord_te_start_time: data.custrecord_te_start_time,
                  custrecord_te_status: data.custrecord_te_status.refName,
                  custrecord_te_timezone: data.custrecord_te_timezone.refName,
                  custrecord_te_waitstudents: data.custrecord_te_waitstudents,
                  custrecord_te_website_notes: data.custrecord_te_website_notes
                    ? data.custrecord_te_website_notes
                    : null,
                  custrecord_te_custom_detail: data.custrecord_te_custom_detail
                    ? data.custrecord_te_custom_detail
                    : null,
                  id: data.id,
                  name: data.name,
                  isInactive: data.isInactive,
                  year: yearToImport,
                  month: monthToImport,
                });
            }
          })
          // save to database year - month
        );
        let insertToYear = await firebase
          .firestore()
          .collection(yearToImport)
          .doc(monthToImport)
          .set({
            events: eventList,
          });

        // if it works then success
        popupAlert(
          "success",
          "Success",
          `${
            eventList.length
          } training events for ${selectedMonth.toUpperCase()}, ${selectedYear} updated`
        );
      }
    } catch (error) {
      popupAlert("error", "Error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = () => {
    let monthNumber = moment().month(selectedMonth).format("MM");
    let endOfMonth = moment(`${selectedYear}-${monthNumber}`)
      .endOf("month")
      .format("D");
    let twoDigetYear = moment().year(selectedYear).format("YY");

    getData(endOfMonth, monthNumber, twoDigetYear, selectedMonth, selectedYear);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1 style={{ textAlign: "center" }}>Imports / Update Events</h1>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <div className="event-filters" style={{ justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Month
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedMonth}
                onChange={handleMonthChange}
                label="Month"
              >
                <MenuItem value={selectedMonth} disabled>
                  {selectedMonth.toUpperCase()}
                </MenuItem>

                {mapMonth.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* year  */}
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Month
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedYear}
                onChange={handleYearChange}
                label="Month"
              >
                <MenuItem value={selectedYear} disabled>
                  {selectedYear}
                </MenuItem>
                <MenuItem value={moment().format("YYYY").toString()}>
                  {moment().format("YYYY").toString()}
                </MenuItem>
                <MenuItem value={nextYear}>{nextYear}</MenuItem>
              </Select>
              {/* <FormHelperText>Selected Year: {selectedYear}</FormHelperText> */}
            </FormControl>

            {/* todaysDate ===
            moment(refreshTokenInfo.refreshTokenExpire).format("LL") && */}
            <div>
              {isLoading ? (
                <p>Getting data, please wait...</p>
              ) : (
                <AdminBrandButton
                  variant="contained"
                  color="primary"
                  title="Import / Update Events"
                  onClick={handleSubmit}
                  bgColorPrimary={true}
                />
              )}
            </div>
          </div>
          <div>{isLoading && <LinearProgress />}</div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ImportEvents;
