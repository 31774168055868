import React, { useState, useEffect } from "react";
import firebase from "../../config/fbConfig";

//compnents
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Skeleton from "@material-ui/lab/Skeleton";
import AddEventModal from "./AddEventModal";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BrandButton from "../button/BrandButton";
import Paper from "@material-ui/core/Paper";

//utils
import moment from "moment";
import HrsAdminEventList from "./HrsAdminEventList";
import { popupAlert } from "../../utils/popupAlert";
import quickSort from "../../utils/quickSort";
import Swal from "sweetalert2";
import { nextYear } from "../../utils/dateManipulate";

const useStyles = makeStyles((theme) => ({
  container: {
    // display: "flex",
    // flexWrap: "wrap",
    minHeight: "100vh",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  skeleton: {
    width: "100%",
    height: 80,
  },
}));

const HrsEventsDashboard = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedYear, setSelectedYear] = useState(
    moment().format("YYYY").toString()
  );
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addEventVisible, setAddEventVisible] = useState(false);
  const [noData, setNoData] = useState(false);
  // load and fetch data for that year

  useEffect(() => {
    loadYearEvent(selectedYear);
  }, []);

  const loadYearEvent = async (y) => {
    setIsLoading(true);
    try {
      let eventDataArray = [];

      let querySnapShot = await firebase
        .firestore()
        .collection(`${y}-hrs-events`)
        .get();

      if (querySnapShot.size >= 1) {
        querySnapShot.forEach((doc) => {
          eventDataArray.push({ id: doc.id, ...doc.data() });
        });

        setEventData(quickSort(eventDataArray, "eventStartDate", "desc"));
        setNoData(false);
      } else {
        popupAlert("error", "Error", "Unable to load, please try again later");
        setEventData([]);
        setNoData(true);
      }
    } catch (error) {
      popupAlert("error", "Error", "Unable to load, please try again later");
      setEventData([]);
      setNoData(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (eventId, name, startDate) => {
    Swal.fire({
      title: `Deleting ${name} - ${moment(startDate).format("LL")}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2188c9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          let year = moment(startDate).format("YYYY").toString();
          // delete from fb
          firebase
            .firestore()
            .collection(`${year}-hrs-events`)
            .doc(eventId)
            .delete()
            .then(() => {
              Swal.fire(
                "Deleted!",
                `Event ID: ${eventId} successfully deleted`,
                "success"
              );

              let deleteId = eventId;
              setEventData(eventData.filter((i) => i.id !== deleteId));
            })
            .catch((error) => {
              popupAlert(
                "error",
                "Error",
                `Unable to delete event ID: ${eventId}, please try again later`
              );
            });
        }
      })
      .catch((error) => {
        popupAlert(
          "error",
          "Error",
          `Unable to delete event ID: ${eventId}, please try again later`
        );
      });
  };

  const handleToggle = async (eventId, name, startDate, status) => {
    Swal.fire({
      title: `${status ? "Disable" : "Enable"} ${name} - ${moment(
        startDate
      ).format("LL")}`,
      text: `You will be ${status ? "disabling" : "enabling"} this event`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#2188c9",
      cancelButtonColor: "#d33",
      confirmButtonText: `${status ? "Disable" : "Enable"}`,
    })
      .then((result) => {
        if (result.isConfirmed) {
          let year = moment(startDate).format("YYYY").toString();
          // delete from fb

          firebase
            .firestore()
            .collection(`${year}-hrs-events`)
            .doc(eventId)
            .update({
              isActive: !status,
            })
            .then(() => {
              Swal.fire(
                "Updated!",
                `Event ID: ${eventId} successfully updated!`,
                "success"
              );

              setTimeout(() => {
                window.location.reload();
              }, 3000);
            })
            .catch((error) => {
              popupAlert(
                "error",
                "Error",
                `Unable to update event ID: ${eventId}, please try again later`
              );
            });
        }
      })
      .catch((error) => {
        popupAlert("error", "Error", error);
      });
  };

  const toggleAddEventModal = () => {
    setAddEventVisible((prevAddEventVisible) => !addEventVisible);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleFilterData = () => {
    loadYearEvent(selectedYear);
  };

  if (isLoading) {
    return (
      <div>
        <Skeleton animation="wave" className={classes.skeleton} />
        <Skeleton animation="wave" className={classes.skeleton} />
        <Skeleton animation="wave" className={classes.skeleton} />
        <Skeleton animation="wave" className={classes.skeleton} />
        <Skeleton animation="wave" className={classes.skeleton} />
        <Skeleton animation="wave" className={classes.skeleton} />
      </div>
    );
  }

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <div className="event-filters">
          {/* year  */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedYear}
              onChange={handleYearChange}
              label="Month"
            >
              <MenuItem value={selectedYear} disabled>
                {selectedYear}
              </MenuItem>
              <MenuItem value={moment().format("YYYY").toString()}>
                {moment().format("YYYY").toString()}
              </MenuItem>
              <MenuItem value={nextYear}>{nextYear}</MenuItem>
            </Select>
            {/* <FormHelperText>Selected Year: {selectedYear}</FormHelperText> */}
          </FormControl>
          <BrandButton
            title="Apply Filters"
            bgColorPrimary={true}
            onClick={handleFilterData}
          />
        </div>

        {noData ? (
          <Paper elevation={3}>
            <p style={{ padding: 20 }}>
              Sorry, no event, please adjust your filters
            </p>
          </Paper>
        ) : (
          <HrsAdminEventList
            eventData={eventData}
            handleDelete={handleDelete}
            handleToggle={handleToggle}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <div style={{ position: "fixed", bottom: 0, right: 0, zIndex: 99999 }}>
          <IconButton onClick={() => toggleAddEventModal()}>
            <AddCircleIcon fontSize="large" style={{ color: "#2188c9" }} />
          </IconButton>
        </div>
        <AddEventModal
          closeModal={toggleAddEventModal}
          visible={addEventVisible}
        />
      </Grid>
    </Grid>
  );
};

export default HrsEventsDashboard;
