import React from "react";
import Grid from "@material-ui/core/Grid";
import EventList from "../events/EventList";
import { getProductNameFromUrl } from "../../utils/getProductUrl";

const Events = (props) => {
  const selectedCategory = props.match.params.selectedCategory;
  let currentYear = new Date().getFullYear().toString();

  if (getProductNameFromUrl(selectedCategory) === "none") {
    return (
      <Grid container spacing={3}>
        <p>None</p>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <EventList
        category={getProductNameFromUrl(selectedCategory)}
        disableSelectCourse={true}
        currentYear={currentYear}
      />
    </Grid>
  );
};

export default Events;
