export const getWithExpiry = async (key) => {
  const itemStr = await localStorage.getItem(key);
  const expiryStr = await localStorage.getItem(`${key}-expiry`);

  if (!itemStr && !expiryStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const expiry = JSON.parse(expiryStr);
  const now = new Date();

  if (now.getTime() > expiry) {
    let removeItemStr = await localStorage.removeItem(key);
    let removeExpiryStr = await localStorage.removeItem(`${key}-expiry`);
    return null;
  }

  return item;
};

export const setWithExpiry = async (key, value) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire

  let setLocalStorage = await localStorage.setItem(key, JSON.stringify(value));
  let setLocalStroageExpire = await localStorage.setItem(
    `${key}-expiry`,
    JSON.stringify(now.getTime() + 300000)
  );
};
