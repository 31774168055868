import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// components
import Paper from "@material-ui/core/Paper";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import WebIcon from "@material-ui/icons/Web";
import Chip from "@material-ui/core/Chip";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AdminBrandButton from "../button/AdminBrandButton";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Language";

// utils
import convertTime from "../../utils/convertTime";
import { getProductUrl } from "../../utils/getProductUrl";

// assets
import signInLogo from "../../assets/sign-in-logo.png";
// style

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: 20,
    // paddingBottom: 20,
    // height: "100vh",
    // marginTop: "10vh",
    // marginBottom: "10vh",
    // overflow: "hidden",
  },
  form: {
    padding: 20,
  },
  image: {
    padding: 20,
  },
}));

const IndividualEvent = ({ item }) => {
  const classes = useStyles();
  const {
    custrecord_te_class_name,
    // custrecord_te_class_sku,
    custrecord_te_class_type,
    // custrecord_te_classroom,
    // custrecord_te_closed_class,
    custrecord_te_confstudents,
    custrecord_te_end_date,
    custrecord_te_end_time,
    custrecord_te_start_date,
    custrecord_te_start_time,
    custrecord_te_timezone,
    custrecord_te_location,
    custrecord_te_maxstudents,
    // custrecord_te_gennotes,
    // custrecord_te_status,
    custrecord_te_regstudents,
    custrecord_te_custom_detail,
    custrecord_te_website_notes,
    // isInactive,
    // month,
    // year,
    name,
    id,
  } = item;

  //   console.log(custrecord_te_end_date);

  const handleClick = (selectedButton) => {
    let urlTitle = custrecord_te_class_name
      .substr(custrecord_te_class_name.indexOf(":") + 1)
      .trim()
      .replaceAll(/\s/g, "+");
    let urlStartDate = moment(custrecord_te_start_date)
      .format("LL")
      .replaceAll(/\s/g, "+");
    let urlEndDate = moment(custrecord_te_end_date)
      .format("LL")
      .replaceAll(/\s/g, "+");

    let buyNowLink = "https://hawkridgesys.com/";
    let registrationLink = "https://hawkridgesys.com/training-registration?";
    let joinWaitListLink = "https://hawkridgesys.com/training-waitlist?";

    if (selectedButton === "buyNow") {
      window.open(
        `${buyNowLink}${getProductUrl(
          custrecord_te_class_name
        )}?className=${urlTitle}&startDate=${urlStartDate}&classId=${name}`
      );
    }

    if (selectedButton === "alreadyRegister") {
      window.open(
        `${registrationLink}className=${urlTitle}&date=${urlStartDate}+-+${urlEndDate}&classId=${id}&spaces=1&time=${convertTime(
          custrecord_te_start_time
        )}+-+${convertTime(custrecord_te_end_time)}+${custrecord_te_timezone}&location=${
          custrecord_te_location === "00_Online Training"
            ? "Online"
            : custrecord_te_location
        }&deliveryType=${custrecord_te_class_type}`
      );
    }

    if (selectedButton === "joinWaitlist") {
      window.open(
        `${joinWaitListLink}className=${urlTitle}&date=${urlStartDate}+-+${urlEndDate}&classId=${id}&spaces=1&time=${convertTime(
          custrecord_te_start_time
        )}+-+${convertTime(custrecord_te_end_time)}+${custrecord_te_timezone}&location=${
          custrecord_te_location === "00_Online Training"
            ? "Online"
            : custrecord_te_location
        }&deliveryType=${custrecord_te_class_type}`
      );
    }
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <div className="individual-container">
        <div className="individual-inner">
          <div className="individual-event__info">
            <div className="individual-event__content">
              <div className="individual-logo">
                <img src={signInLogo} alt="Hawk Ridge Systems" />
              </div>
              <div className="individual-title primary-color">
                <h2>
                  {custrecord_te_custom_detail
                    ? custrecord_te_custom_detail
                    : custrecord_te_class_name.substr(
                        custrecord_te_class_name.indexOf(":") + 1
                      )}
                </h2>
              </div>
              <div className="event-item__text">
                <span style={{ color: "red", marginRight: 5 }}>
                  Delivery Type:{" "}
                </span>{" "}
                {custrecord_te_class_type}
              </div>
              <div className="event-dates">
                <Chip
                  icon={<DateRangeIcon />}
                  label={`${moment(custrecord_te_start_date).format("LL")} - 
                       ${moment(custrecord_te_end_date).format("LL")}`}
                />
              </div>
              <div className="event_location_time">
                <Chip
                  icon={<ScheduleIcon />}
                  label={`${convertTime(
                    custrecord_te_start_time
                  )} - ${convertTime(custrecord_te_end_time)}
                        ${custrecord_te_timezone}`}
                />{" "}
                <Chip
                  icon={
                    custrecord_te_location === "00_Online Training" ? (
                      <LanguageIcon />
                    ) : (
                      <RoomIcon />
                    )
                  }
                  label={`${
                    custrecord_te_location === "00_Online Training"
                      ? "An Online Course"
                      : custrecord_te_location
                  }`}
                />
              </div>

              {custrecord_te_class_type === "Private" ||
              custrecord_te_class_type === "Tailored" ? (
                ""
              ) : (
                <div className="spots_remain">
                  <span className="event-info-rsvp-top">
                    <span className="event-info-rsvp-remaining">
                      <em>
                        {custrecord_te_regstudents +
                          custrecord_te_confstudents >=
                        custrecord_te_maxstudents
                          ? 0
                          : custrecord_te_maxstudents -
                            (custrecord_te_confstudents +
                              custrecord_te_regstudents)}
                      </em>
                      Spots remaining
                    </span>
                  </span>
                </div>
              )}
              {custrecord_te_website_notes ? (
                <div className="event-item__text">
                  <span style={{ color: "red", marginRight: 5 }}>NOTES:</span>
                  {custrecord_te_website_notes}
                </div>
              ) : (
                ""
              )}
              {custrecord_te_class_type === "Private" ||
              custrecord_te_class_type === "Tailored" ? (
                <div className="individual-buttons private-tailored">
                  <AdminBrandButton
                    title="REGISTER NOW"
                    onClick={() => handleClick("alreadyRegister")}
                  />
                </div>
              ) : (
                <div className="individual-buttons regular">
                  {custrecord_te_regstudents + custrecord_te_confstudents >=
                  custrecord_te_maxstudents ? (
                    <AdminBrandButton
                      title="Join Waitlist"
                      bgColorPrimary={true}
                      onClick={() => handleClick("joinWaitlist")}
                      style={{ marginRight: 20 }}
                    />
                  ) : (
                    <AdminBrandButton
                      bgColorPrimary={true}
                      title="Buy Now"
                      onClick={() => handleClick("buyNow")}
                    />
                  )}

                  {custrecord_te_regstudents + custrecord_te_confstudents >=
                  custrecord_te_maxstudents ? (
                    ""
                  ) : (
                    <AdminBrandButton
                      title="REGISTER NOW"
                      onClick={() => handleClick("alreadyRegister")}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* end invent info  */}
          <div className="individual-contact" style={{ background: "#2189c9" }}>
            <div className="individual-contact__inner">
              <div className="individual-contact__content">
                <div className="individual-details color-white">
                  <div className="individual-contact__title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="individual-contact__email individual-flex">
                    <div className="individual-icon">
                      <MailOutlineIcon />
                    </div>
                    <div>
                      <p>
                        <strong>Email:</strong>{" "}
                        <a href="mailto:training@hawkridgesys.com">
                          training@hawkridgesys.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="individual-contact__phone individual-flex">
                    <div className="individual-icon">
                      <PhoneIphoneIcon />
                    </div>
                    <div>
                      <p>
                        <strong>US:</strong>{" "}
                        <a href="tel:8772664469">877.266.4469</a>
                      </p>
                      <p>
                        <strong>Canada:</strong>{" "}
                        <a href="tel:8665876803">866.587.6803</a>
                      </p>
                    </div>
                  </div>
                  <div className="individual-contact__email individual-flex">
                    <div className="individual-icon">
                      <WebIcon />
                    </div>
                    <div>
                      <p>
                        <strong>Website:</strong>{" "}
                        <a href="https://hawkridgesys.com">hawkridgesys.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end individual contact  */}
        </div>
      </div>
    </Paper>
  );
};

export default IndividualEvent;
