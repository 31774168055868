import React from "react";
import firebase from "../../config/fbConfig";
// redux
import { logout } from "../../redux/reducers/loginReducers";
import { useDispatch } from "react-redux";

// components
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

// utlis
import { popupAlert } from "../../utils/popupAlert";

const SignedInLinks = () => {
  const dispatch = useDispatch();
  // const [isLoggedOut, setIsLoggedOut] = useState();

  const userLogout = async () => {
    try {
      let signOut = await firebase.auth().signOut();

      dispatch(logout());

      popupAlert("success", "Logged out", "Successfully Logged out");
    } catch (error) {
      popupAlert("error", "error", "ERROR: 1738, please contact web developer");
    }

    // firebase
    //   .auth()
    //   .signOut()
    //   .then(() => {
    //     dispatch(logout());
    //     popupAlert("success", "Logged out", "Successfully Logged out");
    //   })
    //   .catch((error) => {
    //     popupAlert(
    //       "error",
    //       "error",
    //       "ERROR: 1738, please contact web developer"
    //     );
    //   });
  };

  return (
    <div>
      <Button component={Link} to="/dashboard" color="inherit">
        Dashboard
      </Button>
      <Button component={Link} to="/hrs-dashboard" color="inherit">
        HRS-EVENTS
      </Button>
      <Button component={Link} to="/update-event" color="inherit">
        Update/Add TRAINING Event
      </Button>
      <Button component={Link} to="/import-events" color="inherit">
        Import TRAINING Events
      </Button>
      <Button component={Link} to="/course-schedule" color="inherit">
        Course Schedule
      </Button>
      <Button onClick={() => userLogout()} color="inherit">
        Log Out
      </Button>
    </div>
  );
};

export default SignedInLinks;
