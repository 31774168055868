import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../config/fbConfig";
import "../events/events.css";

// components
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BrandButton from "../button/BrandButton";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from "../skeleton/Skeleton";

// utils
import quickSort from "../../utils/quickSort";
import { popupAlert } from "../../utils/popupAlert";
import moment from "moment";
import { nextYear, oneWeekDate } from "../../utils/dateManipulate";
import { setWithExpiry, getWithExpiry } from "../../utils/localStorageUtils";
import {
  mapMonth,
  classesObjectArrayNameOnly,
} from "../../utils/constArraydata";
import { getProductUrl } from "../../utils/getProductUrl";
import HrsEventSummary from "./HrsEventSummary";

const useStyles = makeStyles((theme) => ({
  eventListContainer: {
    paddingTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlName: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HrsEvents = () => {
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    loadHrsEvents(selectedYear);
  }, []);

  const loadHrsEvents = async (y) => {
    setIsLoading(true);
    let eventData = [];

    try {
      let getLocalHrsEventsData = await getWithExpiry(`${y}-hrs-events`);

      if (getLocalHrsEventsData) {
        // console.log("Loading from local storage");
        setNoData(false);
        setEvents(getLocalHrsEventsData);
      } else {
        let data = await firebase
          .firestore()
          .collection(`${y}-hrs-events`)
          .where("isActive", "==", true)
          .get();

        if (data.size !== 0) {
          data.forEach((doc) => {
            if (
              moment(doc.data().eventEndDate).format("YYYY-MM-DD") >=
              moment().format("YYYY-MM-DD")
            ) {
              eventData.push(doc.data());
            }
          });
          setWithExpiry(
            `${y}-hrs-events`,
            quickSort(eventData, "eventStartDate", "desc")
          );
          setEvents(quickSort(eventData, "eventStartDate", "desc"));
        } else {
          setWithExpiry(`${y}-hrs-events}`, null);
          setEvents([]);
          setNoData(true);
        }
      }
    } catch (error) {
      popupAlert("error", "Error", "Please try again later");
      setNoData(true);
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleFilterData = () => {
    loadHrsEvents(selectedYear);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Grid container spacing={3} className={classes.eventListContainer}>
      <Grid item xs={12}>
        <div className="event-filters">
          {/* year  */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedYear}
              onChange={handleYearChange}
              label="Month"
            >
              <MenuItem value={selectedYear} disabled>
                {selectedYear}
              </MenuItem>
              <MenuItem value={moment().format("YYYY").toString()}>
                {moment().format("YYYY").toString()}
              </MenuItem>
              <MenuItem value={nextYear}>{nextYear}</MenuItem>
            </Select>
            {/* <FormHelperText>Selected Year: {selectedYear}</FormHelperText> */}
          </FormControl>
          <BrandButton
            title="Apply Filter"
            bgColorPrimary={true}
            onClick={handleFilterData}
          />
        </div>
      </Grid>

      {noData || events.length === 0 ? (
        <>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <p style={{ padding: 20 }}>
                Sorry, no events, please adjust your filters or{" "}
                <a href="/contact-us" style={{ color: "#2188c9" }}>
                  contact us
                </a>
                .
              </p>
            </Paper>
          </Grid>
        </>
      ) : (
        <>
          {events.map((item, index) => (
            <HrsEventSummary key={index} item={item} />
          ))}
        </>
      )}
    </Grid>
  );
};

export default HrsEvents;
