const quickSort = (events, key, dir) => {
  const desc = dir === "desc";

  const list = [...events];

  return list.sort((a, b) => {
    return desc ? (b[key] > a[key] ? -1 : 1) : b[key] < a[key] ? -1 : 1;
  });
};

export default quickSort;
