import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "./redux/reducers/loginReducers";
import { changeLoading } from "./redux/reducers/loaderReducers";
import firebase from "./config/fbConfig";
// styles
import { makeStyles } from "@material-ui/core/styles";
// components
import Dashboard from "./components/dashboard/Dashboard";
import Navbar from "./components/layout/Navbar";
import SignIn from "./components/auth/SignIn";
import CourseSchedule from "./components/events/CourseSchedule";
import UpdateEvent from "./components/events/UpdateEvent";
import Events from "./components/events/Events";
import Container from "@material-ui/core/Container";
//reducers
import { logout } from "./redux/reducers/loginReducers";

//hooks
import { popupAlert } from "./utils/popupAlert";
import ImportEvents from "./components/events/ImportEvents";
import EventDetails from "./components/events/EventDetails";
import HrsEventsDashboard from "./components/hrsEvents/HrsEventsDashboard";
import HrsEvents from "./components/hrsEvents/HrsEvents";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
  },
}));

const App = () => {
  const isUserLoggedIn = useSelector((state) => state.login.isLogin);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    loadCurrentUser();
  }, []);

  const loadCurrentUser = async () => {
    try {
      let checkAuthStateChange = await firebase
        .auth()
        .onAuthStateChanged(async (user) => {
          if (user) {
            dispatch(isLoggedIn(user.uid, user.email, true, user.refreshToken));
            dispatch(changeLoading(false));
          } else {
            dispatch(logout());
            dispatch(changeLoading(false));
          }
        });
    } catch (error) {
      popupAlert("error", "Error", error.message);
      dispatch(changeLoading(false));
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <BrowserRouter>
      <div className="App">
        {/* <Drawer /> */}
        {isUserLoggedIn && <Navbar isUserLoggedIn={isUserLoggedIn} />}
        <Container maxWidth="xl" className={classes.root}>
          <Switch>
            <Route exact path="/">
              {isUserLoggedIn ? <Redirect to="/dashboard" /> : <SignIn />}
            </Route>
            <Route path="/dashboard">
              {isUserLoggedIn ? (
                <Dashboard isUserLoggedIn={isUserLoggedIn} />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/events" component={HrsEvents} />
            <Route path="/update-event">
              {isUserLoggedIn ? <UpdateEvent /> : <SignIn />}
            </Route>
            <Route path="/course-schedule" component={CourseSchedule} />
            <Route path="/import-events">
              {isUserLoggedIn ? <ImportEvents /> : <SignIn />}
            </Route>
            <Route path="/hrs-dashboard">
              {isUserLoggedIn ? <HrsEventsDashboard /> : <SignIn />}
            </Route>
            <Route
              path="/event/:year/:id"
              component={EventDetails}
              isUserLoggedIn={isUserLoggedIn}
            />
            <Route path="/:selectedCategory" component={Events} />
          </Switch>
        </Container>
      </div>
    </BrowserRouter>
  );
};

export default App;
