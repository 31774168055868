import React from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";

const SignedOutLinks = () => {
  return (
    <div>
      <Button component={NavLink} to="/" color="inherit">
        Sign In
      </Button>
      {/* <Button component={NavLink} to="/signin" color="inherit">
        Log In
      </Button> */}
    </div>
  );
};

export default SignedOutLinks;
