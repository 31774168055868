import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./events.css";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// componetns
import BrandButton from "../button/BrandButton";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Language";
import Chip from "@material-ui/core/Chip";
import EventAdminSummary from "./EventAdminSummary";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";

// utils
import convertTime from "../../utils/convertTime";
import { imageCheck } from "../../utils/ImageCheck";
import { getProductUrl } from "../../utils/getProductUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px !important",
    // borderBottom: "1px solid lightgray",
  },
  formControl: {
    //
  },
}));

const EventSummary = ({ item, disableSelectCourse }) => {
  let width = window.innerWidth;
  const classes = useStyles();
  const {
    custrecord_te_class_name,
    // custrecord_te_class_sku,
    custrecord_te_class_type,
    // custrecord_te_classroom,
    // custrecord_te_closed_class,
    custrecord_te_confstudents,
    custrecord_te_end_date,
    custrecord_te_end_time,
    custrecord_te_start_date,
    custrecord_te_start_time,
    custrecord_te_timezone,
    custrecord_te_location,
    custrecord_te_maxstudents,
    custrecord_te_custom_detail,
    custrecord_te_website_notes,
    // custrecord_te_gennotes,
    // custrecord_te_status,
    custrecord_te_regstudents,
    // isInactive,
    // month,
    // year,
    name,
    id,
  } = item;

  const isUserLoggedIn = useSelector((state) => state.login.isLogin);

  const handleClick = (selectedButton) => {
    let urlTitle = custrecord_te_class_name
      .substr(custrecord_te_class_name.indexOf(":") + 1)
      .trim()
      .replaceAll(/\s/g, "+");
    let urlStartDate = moment(custrecord_te_start_date)
      .format("LL")
      .replaceAll(/\s/g, "+");
    let urlEndDate = moment(custrecord_te_end_date)
      .format("LL")
      .replaceAll(/\s/g, "+");

    let buyNowLink = "/";
    let registrationLink = "/training-registration?";
    let joinWaitListLink = "/training-waitlist?";

    if (selectedButton === "buyNow" && disableSelectCourse) {
      window.location.href = `${buyNowLink}${getProductUrl(
        custrecord_te_class_name
      )}?className=${urlTitle}&startDate=${urlStartDate}&classId=${id}`;
    }

    if (selectedButton === "alreadyRegister" && disableSelectCourse) {
      window.open(
        `${registrationLink}className=${urlTitle}&date=${urlStartDate}+-+${urlEndDate}&classId=${id}&spaces=1&time=${convertTime(
          custrecord_te_start_time
        )}+-+${convertTime(custrecord_te_end_time)}+${custrecord_te_timezone}&location=${
          custrecord_te_location === "00_Online Training"
            ? "Online"
            : custrecord_te_location
        }&deliveryType=${custrecord_te_class_type}`
      );
    }

    if (selectedButton === "joinWaitlist" && disableSelectCourse) {
      window.open(
        `${joinWaitListLink}className=${urlTitle}&date=${urlStartDate}+-+${urlEndDate}&classId=${id}&spaces=1&time=${convertTime(
          custrecord_te_start_time
        )}+-+${convertTime(custrecord_te_end_time)}+${custrecord_te_timezone}&location=${
          custrecord_te_location === "00_Online Training"
            ? "Online"
            : custrecord_te_location
        }&deliveryType=${custrecord_te_class_type}`
      );
    }

    if (selectedButton === "buyNow" && !disableSelectCourse) {
      window.open(
        `${buyNowLink}${getProductUrl(
          custrecord_te_class_name
        )}?className=${urlTitle}&startDate=${urlStartDate}&classId=${id}`
      );
    }

    if (selectedButton === "alreadyRegister" && !disableSelectCourse) {
      window.open(
        `${registrationLink}className=${urlTitle}&date=${urlStartDate}+-+${urlEndDate}&classId=${id}&spaces=1&time=${convertTime(
          custrecord_te_start_time
        )}+-+${convertTime(custrecord_te_end_time)}+${custrecord_te_timezone}&location=${
          custrecord_te_location === "00_Online Training"
            ? "Online"
            : custrecord_te_location
        }&deliveryType=${custrecord_te_class_type}`
      );
    }

    if (selectedButton === "joinWaitlist" && !disableSelectCourse) {
      window.open(
        `${joinWaitListLink}className=${urlTitle}&date=${urlStartDate}+-+${urlEndDate}&classId=${id}&spaces=1&time=${convertTime(
          custrecord_te_start_time
        )}+-+${convertTime(custrecord_te_end_time)}+${custrecord_te_timezone}&location=${
          custrecord_te_location === "00_Online Training"
            ? "Online"
            : custrecord_te_location
        }&deliveryType=${custrecord_te_class_type}`
      );
    }
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <div className="event-container">
        {/* filters  */}

        {/* filters  */}
        <div className="event-item">
          <div
            className="event-item__left"
            // style={{
            //   backgroundImage: `url(${imageCheck(custrecord_te_class_name)})`,
            // }}
          >
            <img src={`${imageCheck(custrecord_te_class_name)}`} alt="event" />
            <div className="event-bg-overlay"></div>
          </div>
          <div className="event-item__right">
            <div className="event-item__right-info">
              <div className="event-item__right-info-inner">
                <div className="event-item__type">
                  <div className="event-item__title primary-color">
                    {custrecord_te_class_name.substr(
                      custrecord_te_class_name.indexOf(":") + 1
                    )}{" "}
                  </div>
                  <div className="event-item__text">
                    <span style={{ color: "red", marginRight: 5 }}>
                      Delivery Type:{" "}
                    </span>{" "}
                    {custrecord_te_class_type}
                  </div>
                  <div className="event-dates">
                    <Chip
                      icon={<DateRangeIcon />}
                      label={`${moment(custrecord_te_start_date).format(
                        "LL"
                      )} - 
                       ${moment(custrecord_te_end_date).format("LL")}`}
                    />
                  </div>
                  <div className="event_location_time">
                    <Chip
                      icon={<ScheduleIcon />}
                      label={`${convertTime(
                        custrecord_te_start_time
                      )} - ${convertTime(custrecord_te_end_time)}
                        ${custrecord_te_timezone}`}
                    />{" "}
                    <Chip
                      icon={
                        custrecord_te_location === "00_Online Training" ? (
                          <LanguageIcon />
                        ) : (
                          <RoomIcon />
                        )
                      }
                      label={`${
                        custrecord_te_location === "00_Online Training"
                          ? "An Online Course"
                          : custrecord_te_location
                      }`}
                    />
                    <div className="spots_remain">
                      <span className="event-info-rsvp-top">
                        <span className="event-info-rsvp-remaining">
                          <em>
                            {custrecord_te_regstudents +
                              custrecord_te_confstudents >=
                            custrecord_te_maxstudents
                              ? 0
                              : custrecord_te_maxstudents -
                                (custrecord_te_confstudents +
                                  custrecord_te_regstudents)}
                          </em>
                          Spots remaining
                        </span>
                      </span>
                    </div>
                    {custrecord_te_website_notes ? (
                      <div className="event-item__text">
                        <span style={{ color: "red", marginRight: 5 }}>
                          NOTES:
                        </span>
                        {custrecord_te_website_notes}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {isUserLoggedIn && (
                    <a
                      component={Link}
                      href={`/event/${moment(custrecord_te_end_date)
                        .format("YYYY")
                        .toString()}/${id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Individual Event {id}
                    </a>
                  )}
                </div>
              </div>
              <div className="event-buttons">
                <div className="toggle-buttons">
                  {custrecord_te_regstudents + custrecord_te_confstudents >=
                  custrecord_te_maxstudents ? (
                    <BrandButton
                      title="Join Waitlist"
                      bgColorPrimary={true}
                      onClick={() => handleClick("joinWaitlist")}
                      style={{ marginRight: 20 }}
                    />
                  ) : (
                    <BrandButton
                      bgColorPrimary={true}
                      title="Buy Now"
                      onClick={() => handleClick("buyNow")}
                    />
                  )}
                </div>

                {custrecord_te_regstudents + custrecord_te_confstudents >=
                custrecord_te_maxstudents ? (
                  ""
                ) : (
                  <div className="already-register">
                    <span className="primary-color">Already Purchased?</span>
                    <BrandButton
                      title={`${
                        width <= 767 ? "Already Purchased?" : "Register"
                      }`}
                      onClick={() => handleClick("alreadyRegister")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EventSummary;
