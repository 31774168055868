export const imageCheck = (image) => {
  let namePrefix = image.substr(0, image.indexOf(":"));

  switch (namePrefix) {
    case "PDM":
      return "/media/training/Data-Management-NoBG.png";

    case "CAM":
      return "/media/training/Manufacturing.png";

    case "DRIVEW":
      return "media/training/Mechanical-Design-NoBG.png";

    case "SWX":
      return "/media/training/Mechanical-Design-NoBG.png";

    case "SIM":
      return "/media/training/Simulation.png";

    case "SWTECH":
      return "/media/training/Tech-Comm-NoBG.png";

    case "ELEC":
      return "/media/training/Elecrical-NoBG.png";

    default:
      return "/media/training/Mechanical-Design-NoBG.png";
  }
};
