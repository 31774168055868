export const mapMonth = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const classesObjectArray = [
  {
    name: "All",
    url: null,
    prefix: "All",
  },
  {
    name: "PDM: Administering SOLIDWORKS PDM Standard Course",
    url: "administering-solidworks-pdm-standard-course",
    prefix: "PDM",
  },
  {
    name: "PDM: Administering SOLIDWORKS PDM Professional Course",
    url: "administering-solidworks-pdm-professional-course",
    prefix: "PDM",
  },
  {
    name: "CAM: CAM Standard: 2.5 Axis Mill Course",
    url: "cam-standard-2-5-axis-mill-course",
    prefix: "CAM",
  },
  {
    name: "CAM: CAMWorks 3 Axis Mill Course",
    url: "camworks-3-axis-mill-course",
    prefix: "CAM",
  },
  {
    name: "CAM: CAM Professional: Advanced Functionality and Turning Course",
    url: "cam-professional-advanced-functionality-and-turning-course",
    prefix: "CAM",
  },
  {
    name: "CAM: CAMWorks Multi-Axis (4 and 5 Axis) Mill Course",
    url: "camworks-multi-axis-4-and-5-axis-mill-course",
    prefix: "CAM",
  },
  {
    name: "CAM: CAMWorks Multi-Task Mill-Turn Course",
    url: "camworks-multi-task-mill-turn-course",
    prefix: "CAM",
  },
  {
    name: "DRIVEW: DriveWorks  Essentials Course",
    url: "driveworks-essentials-course",
    prefix: "DRIVEW",
  },
  {
    name: "DRIVEW: DriveWorks  Advanced Course",
    url: "driveworks-advanced-course",
    prefix: "DRIVEW",
  },
  {
    name: "SWX: SOLIDWORKS Essentials Course",
    url: "solidworks-essentials-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Drawings Course",
    url: "solidworks-drawings-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Assembly Modeling Course",
    url: "solidworks-assembly-modeling-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Surface Modeling Course",
    url: "solidworks-surface-modeling-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Advanced Part Modeling Course",
    url: "solidworks-advanced-part-modeling-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS File Management Course",
    url: "solidworks-file-management-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Weldments Course",
    url: "solidworks-weldments-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Sheet Metal Course",
    url: "solidworks-sheet-metal-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Mold Design Essentials Course",
    url: "solidworks-mold-design-essentials-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Routing: Electrical Course",
    url: "solidworks-routing-electrical-course",
    prefix: "SWX",
  },
  {
    name: "SWX: SOLIDWORKS Routing: Piping and Tubing Course",
    url: "solidworks-routing-piping-and-tubing-course",
    prefix: "SWX",
  },
  {
    name: "SIM: SOLIDWORKS Simulation Course",
    url: "solidworks-simulation-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Simulation Premium: Nonlinear Course",
    url: "solidworks-simulation-premium-nonlinear-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Flow Simulation Course",
    url: "solidworks-flow-simulation-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Simulation Professional Course",
    url: "solidworks-simulation-professional-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Simulation Premium: Dynamics Course",
    url: "solidworks-simulation-premium-dynamics-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Plastics: Part Validation Course",
    url: "solidworks-plastics-part-validation-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Plastics: Mold Validation Course",
    url: "solidworks-plastics-mold-validation-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Flow Simulation: Rotating Machinery Course",
    url: "solidworks-flow-simulation-rotating-machinery-course",
    prefix: "SIM",
  },
  {
    name: "SIM: SOLIDWORKS Flow Simulation: Electronics Cooling Course",
    url: "solidworks-flow-simulation-electronics-cooling-course",
    prefix: "SIM",
  },
  {
    name: "SWTECH: SOLIDWORKS Visualize Course",
    url: "solidworks-visualize-course",
    prefix: "SWTECH",
  },
  {
    name: "SWTECH: SOLIDWORKS Inspection Course",
    url: "solidworks-inspection-course",
    prefix: "SWTECH",
  },
  {
    name: "SWTECH: SOLIDWORKS Composer Course",
    url: "solidworks-composer-course",
    prefix: "SWTECH",
  },
  {
    name: "ELEC: SOLIDWORKS Electrical 3D Course",
    url: "solidworks-electrical-3d-course",
    prefix: "ELEC",
  },
  {
    name: "ELEC: SOLIDWORKS Electrical Advanced Course",
    url: "solidworks-electrical-advanced-course",
    prefix: "ELEC",
  },
  {
    name: "ELEC: SOLIDWORKS Electrical Schematic Course",
    url: "solidworks-electrical-schematic-course",
    prefix: "ELEC",
  },
  {
    name: "PDM: Using SOLIDWORKS PDM Course",
    url: "using-solidworks-pdm-course",
    prefix: "PDM",
  },
];

export const classesObjectArrayNameOnly = [
  "PDM: Administering SOLIDWORKS PDM Standard Course",

  "PDM: Administering SOLIDWORKS PDM Professional Course",

  "CAM: CAM Professional: Advanced Functionality and Turning Course",

  "CAM: CAM Standard: 2.5 Axis Mill Course",

  "CAM: CAMWorks 3 Axis Mill Course",

  "CAM: CAMWorks Multi-Axis (4 and 5 Axis) Mill Course",

  "CAM: CAMWorks Multi-Task Mill-Turn Course",

  "DRIVEW: DriveWorks  Essentials Course",

  "DRIVEW: DriveWorks  Advanced Course",

  "SWX: SOLIDWORKS Advanced Part Modeling Course",

  "SWX: SOLIDWORKS Assembly Modeling Course",

  "SWTECH: SOLIDWORKS Composer Course",

  "SWX: SOLIDWORKS Drawings Course",

  "ELEC: SOLIDWORKS Electrical 3D Course",

  "ELEC: SOLIDWORKS Electrical Advanced Course",

  "ELEC: SOLIDWORKS Electrical Schematic Course",

  "SWX: SOLIDWORKS Essentials Course",

  "SWX: SOLIDWORKS File Management Course",

  "SIM: SOLIDWORKS Flow Simulation Course",

  "SIM: SOLIDWORKS Flow Simulation: Electronics Cooling Course",

  "SIM: SOLIDWORKS Flow Simulation: Rotating Machinery Course",

  "SWTECH: SOLIDWORKS Inspection Course",

  "SWX: SOLIDWORKS Mold Design Essentials Course",

  "SIM: SOLIDWORKS Plastics: Mold Validation Course",

  "SIM: SOLIDWORKS Plastics: Part Validation Course",

  "SWX: SOLIDWORKS Routing: Electrical Course",

  "SWX: SOLIDWORKS Routing: Piping and Tubing Course",

  "SWX: SOLIDWORKS Sheet Metal Course",

  "SIM: SOLIDWORKS Simulation Course",

  "SIM: SOLIDWORKS Simulation Premium: Dynamics Course",

  "SIM: SOLIDWORKS Simulation Premium: Nonlinear Course",

  "SIM: SOLIDWORKS Simulation Professional Course",

  "SWX: SOLIDWORKS Surface Modeling Course",

  "SWTECH: SOLIDWORKS Visualize Course",

  "SWX: SOLIDWORKS Weldments Course",

  "PDM: Using SOLIDWORKS PDM Course",
];

// classes
export const mapClasses = [
  "All",
  "PDM: Administering SOLIDWORKS PDM Standard Course",
  "PDM: Administering SOLIDWORKS PDM Professional Course",
  "CAM: CAM Standard: 2.5 Axis Mill Course",
  "CAM: CAMWorks 3 Axis Mill Course",
  "CAM: CAM Professional: Advanced Functionality and Turning Course",
  "CAM: CAMWorks Multi-Axis (4 and 5 Axis) Mill Course",
  "CAM: CAMWorks Multi-Task Mill-Turn Course",
  "DRIVEW: DriveWorks  Essentials Course",
  "DRIVEW: DriveWorks  Advanced Course",
  "SWX: SOLIDWORKS Essentials Course",
  "SWX: SOLIDWORKS Drawings Course",
  "SWX: SOLIDWORKS Assembly Modeling Course",
  "SWX: SOLIDWORKS Surface Modeling Course",
  "SWX: SOLIDWORKS Advanced Part Modeling Course",
  "SWX: SOLIDWORKS File Management Course",
  "SWX: SOLIDWORKS Weldments Course",
  "SWX: SOLIDWORKS Sheet Metal Course",
  "SWX: SOLIDWORKS Mold Design Essentials Course",
  "SWX: SOLIDWORKS Routing: Electrical Course",
  "SWX: SOLIDWORKS Routing: Piping and Tubing Course",
  "SIM: SOLIDWORKS Simulation Course",
  "SIM: SOLIDWORKS Simulation Premium: Nonlinear Course",
  "SIM: SOLIDWORKS Flow Simulation Course",
  "SIM: SOLIDWORKS Simulation Professional Course",
  "SIM: SOLIDWORKS Simulation Premium: Dynamics Course",
  "SIM: SOLIDWORKS Plastics: Part Validation Course",
  "SIM: SOLIDWORKS Plastics: Mold Validation Course",
  "SIM: SOLIDWORKS Flow Simulation: Rotating Machinery Course",
  "SIM: SOLIDWORKS Flow Simulation: Electronics Cooling Course",
  "SWTECH: SOLIDWORKS Visualize Course",
  "SWTECH: SOLIDWORKS Inspection Course",
  "SWTECH: SOLIDWORKS Composer Course",
  "ELEC: SOLIDWORKS Electrical 3D Course",
  "ELEC: SOLIDWORKS Electrical Advanced Course",
  "ELEC: SOLIDWORKS Electrical Schematic Course",
  "PDM: Using SOLIDWORKS PDM Course",
  // "3DP: X7 and X5: Basic Operator Training - Self-Paced Course",
  // "3DP: Onyx Pro and Mark2: Basic Operator Training - Self-Paced Course",
  // "3DP: Onyx One Basic Operator Training - Self-Paced Course",
];
