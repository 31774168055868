import React, { useState } from "react";
import firebase from "../../config/fbConfig";
import { makeStyles } from "@material-ui/core/styles";

//components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AdminBrandButton from "../button/AdminBrandButton";

import EventSummary from "./EventSummary";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
// Form
import { useFormik } from "formik";
import { updateEventSchema } from "../../utils/validationSchema";

// utils
import { popupAlert } from "../../utils/popupAlert";
import axios from "axios";
import { oneWeekDate } from "../../utils/dateManipulate";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: 552,
    margin: "auto",
  },
  form: {
    padding: 20,
  },
  image: {
    padding: 20,
  },
  updatedData: {
    marginTop: 20,
  },
}));

const UpdateEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isSubmit, setIsSubmit] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const [compareData, setCompareData] = useState(null);
  const [isNewEvent, setIsNewEvent] = useState(false);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      eventId: "",
    },
    validationSchema: updateEventSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (isNewEvent) {
        setIsNewEvent(false);
      }

      const eventId = values.eventId.trim();

      try {
        let res = await axios.get(
          `https://us-central1-hrs-events-5a2ea.cloudfunctions.net/app/api/v1/event/${eventId}`
        );

        if (res.data.status === 400) {
          popupAlert(
            "error",
            "Error",
            "Event does not exist or you entered a wrong ID, please try again"
          );
        } else {
          let returnEventData = res.data;
          let returnYear = moment(returnEventData.custrecord_te_start_date)
            .format("YYYY")
            .toString();

          let returnMonth = moment(returnEventData.custrecord_te_start_date)
            .format("MMMM")
            .toLowerCase();

          setUpdatedData({
            custrecord_te_class_name:
              returnEventData.custrecord_te_class_name.refName,
            custrecord_te_class_sku:
              returnEventData.custrecord_te_class_sku.refName,
            custrecord_te_class_type:
              returnEventData.custrecord_te_class_type.refName,
            custrecord_te_classroom:
              returnEventData.custrecord_te_classroom.refName,
            id: eventId,
            custrecord_te_closed_class:
              returnEventData.custrecord_te_closed_class,
            custrecord_te_confstudents:
              returnEventData.custrecord_te_confstudents,
            custrecord_te_end_date: returnEventData.custrecord_te_end_date,
            custrecord_te_end_time: returnEventData.custrecord_te_end_time,
            custrecord_te_location:
              returnEventData.custrecord_te_location.refName,
            custrecord_te_maxstudents:
              returnEventData.custrecord_te_maxstudents,
            custrecord_te_regstudents:
              returnEventData.custrecord_te_regstudents,
            custrecord_te_start_date: returnEventData.custrecord_te_start_date,
            custrecord_te_start_time: returnEventData.custrecord_te_start_time,
            custrecord_te_status: returnEventData.custrecord_te_status.refName,
            custrecord_te_timezone:
              returnEventData.custrecord_te_timezone.refName,
            custrecord_te_waitstudents:
              returnEventData.custrecord_te_waitstudents,
            isInactive: returnEventData.isInactive,
            custrecord_te_website_notes:
              returnEventData.custrecord_te_website_notes
                ? returnEventData.custrecord_te_website_notes
                : null,
            custrecord_te_custom_detail:
              returnEventData.custrecord_te_custom_detail
                ? returnEventData.custrecord_te_custom_detail
                : null,
            month: returnMonth,
            name: returnEventData.name,
            year: returnYear,
          });

          // get data from firebase
          let getEventData = await firebase
            .firestore()
            .collection(`${returnYear}-events`)
            .doc(eventId)
            .get();

          if (getEventData.exists) {
            setCompareData(getEventData.data());
          } else {
            // allow add
            setIsNewEvent(true);
          }
        }
      } catch (error) {
        popupAlert("error", "Error", error.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleUpdate = async () => {
    setIsLoading(true);
    if (
      compareData.custrecord_te_class_name ===
        updatedData.custrecord_te_class_name &&
      compareData.custrecord_te_class_sku ===
        updatedData.custrecord_te_class_sku &&
      compareData.custrecord_te_class_type ===
        updatedData.custrecord_te_class_type &&
      compareData.custrecord_te_classroom ===
        updatedData.custrecord_te_classroom &&
      compareData.custrecord_te_closed_class ===
        updatedData.custrecord_te_closed_class &&
      compareData.custrecord_te_confstudents ===
        updatedData.custrecord_te_confstudents &&
      compareData.custrecord_te_end_date ===
        updatedData.custrecord_te_end_date &&
      compareData.custrecord_te_end_time ===
        updatedData.custrecord_te_end_time &&
      compareData.custrecord_te_location ===
        updatedData.custrecord_te_location &&
      compareData.custrecord_te_maxstudents ===
        updatedData.custrecord_te_maxstudents &&
      compareData.custrecord_te_regstudents ===
        updatedData.custrecord_te_regstudents &&
      compareData.custrecord_te_start_date ===
        updatedData.custrecord_te_start_date &&
      compareData.custrecord_te_start_time ===
        updatedData.custrecord_te_start_time &&
      compareData.custrecord_te_status === updatedData.custrecord_te_status &&
      compareData.custrecord_te_timezone ===
        updatedData.custrecord_te_timezone &&
      compareData.custrecord_te_waitstudents ===
        updatedData.custrecord_te_waitstudents &&
      compareData.id === updatedData.id &&
      compareData.isInactive === updatedData.isInactive &&
      compareData.month === updatedData.month &&
      compareData.name === updatedData.name &&
      compareData.year === updatedData.year
    ) {
      popupAlert(
        "error",
        "Error",
        `No data was updated in NetSuite for TE-${compareData.id}`
      );
      setCompareData(null);
      setUpdatedData(null);
      setIsLoading(false);
    } else {
      // updated
      try {
        let saveData = await firebase
          .firestore()
          .collection(`${compareData.year}-events`)
          .doc(compareData.id)
          .update(updatedData);
      } catch (error) {
        popupAlert("error", "Error", "Unable to save, please try again later");
      } finally {
        popupAlert("success", "Success", `Event ID: ${compareData.id} Updated`);
        setCompareData(null);
        setUpdatedData(null);
        setIsNewEvent(null);
        setIsLoading(false);
      }
    }
  };

  const handleAdd = async () => {
    setIsLoading(true);
    // let addToYearCollection = await
    let y = updatedData.year;
    let m = updatedData.month;
    let eventId = updatedData.id;
    console.log(updatedData);
    try {
      /// check if class is cancel or not
      if (
        (moment(updatedData.custrecord_te_start_date).format("YYYY-MM-DD") >
          oneWeekDate() &&
          updatedData.custrecord_te_status === "Scheduled") ||
        "Confirmed"
      ) {
        // add
        let saveDataToEvents = await firebase
          .firestore()
          .collection(`${y}-events`)
          .doc(eventId)
          .set(updatedData);

        let saveDataToYear = await firebase
          .firestore()
          .collection(y)
          .doc(m)
          .set(
            {
              events: firebase.firestore.FieldValue.arrayUnion({
                custrecord_te_class_name: updatedData.custrecord_te_class_name,
                custrecord_te_closed_class:
                  updatedData.custrecord_te_closed_class,
                custrecord_te_start_date: updatedData.custrecord_te_start_date,
                custrecord_te_status: updatedData.custrecord_te_status,
                id: eventId,
                isInactive: updatedData.isInactive,
                custrecord_te_class_type: updatedData.custrecord_te_class_type,
              }),
            },
            { merge: true }
          );
        popupAlert("success", "Success", `Event ID: ${eventId} Updated`);
      } else {
        popupAlert(
          "error",
          "Error",
          `${eventId} is either cancelled, completed, or in the past. Please try another event ID`
        );
      }
    } catch (error) {
      popupAlert("error", "Error", "Unable to save, please try again later");
    } finally {
      setCompareData(null);
      setUpdatedData(null);
      setIsLoading(false);
      setIsNewEvent(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1 style={{ textAlign: "center" }}>Update/Add Event</h1>
        <Paper elevation={3} className={classes.formContainer}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="eventId"
              name="eventId"
              label="Event ID"
              value={formik.values.eventId}
              onChange={formik.handleChange}
              error={formik.touched.eventId && Boolean(formik.errors.eventId)}
              helperText={formik.touched.eventId && formik.errors.eventId}
            />
            {!isLoading && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <AdminBrandButton
                  title="Update / Add"
                  bgColorPrimary={true}
                  type="submit"
                  style={{ width: "100%" }}
                />
              </div>
            )}
          </form>
          {isLoading && <LinearProgress />}
        </Paper>
        {updatedData && (
          <Paper elevation={0} className={classes.updatedData}>
            <h3 style={{ textAlign: "center" }}>Incoming data</h3>
            <EventSummary item={updatedData} />
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <ArrowDownwardIcon fontSize="large" />
            </div>
          </Paper>
        )}
        {compareData && (
          <Paper elevation={0} className={classes.updatedData}>
            <h3 style={{ textAlign: "center" }}>Overwritting</h3>
            <EventSummary item={compareData} />
            <div style={{ marginTop: 20 }}>
              {!isLoading && (
                <AdminBrandButton
                  title="Update"
                  bgColorPrimary={true}
                  style={{ width: "100%" }}
                  onClick={() => handleUpdate()}
                />
              )}
              {isLoading && <LinearProgress />}
            </div>
          </Paper>
        )}
        {isNewEvent && (
          <Paper elevation={0} className={classes.updatedData}>
            <h3 style={{ textAlign: "center" }}>
              This event is new. Do you want to add the event?
            </h3>
            <div style={{ marginTop: 20 }}>
              {!isLoading && (
                <AdminBrandButton
                  title="Add"
                  bgColorPrimary={true}
                  onClick={() => handleAdd()}
                  style={{ width: "100%" }}
                />
              )}
              {isLoading && <LinearProgress />}
            </div>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default UpdateEvent;
