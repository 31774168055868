import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import BrandButton from "../button/BrandButton";
import firebase from "../../config/fbConfig";
import axios from "axios";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import {
  getEventMonthToLowerString,
  getEventYearToString,
} from "../../utils/dateManipulate";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Dashboard = ({ isUserLoggedIn }) => {
  const [personName, setPersonName] = React.useState([]);
  const classes = useStyles();
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const [events, setEvents] = useState([]);

  // console.log(personName);
  let eventArray = [];

  // const handleClick = async () => {
  //   let db = firebase.firestore();

  //   let first = await db
  //     .collection("2021-events")
  //     .orderBy("id", "desc")
  //     .startAfter("17291")
  //     .limit(20)
  //     .get()
  //     .then((querySnapshot) => {
  //       console.log(querySnapshot.size);
  //       let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1].id;

  //       console.log(lastVisible);

  //       if (querySnapshot.size !== 0) {
  //         querySnapshot.forEach((doc) => {
  //           if (doc.exists) {
  //             console.log(doc.id);
  //           } else {
  //             console.log("Does not exists");
  //           }
  //         });
  //       } else {
  //         // reset the database
  //         console.log("reset");
  //       }
  //     });

  //   // return first.get().then((documentSnapshots) => {
  //   //   // get the last visible document
  //   //   let lastVisible =
  //   //     documentSnapshots.docs[documentSnapshots.docs.length - 1];

  //   //   console.log("last", lastVisible);

  //   //   // get the next 25
  //   //   let next = db
  //   //     .collection("2021-events")
  //   //     .orderBy("id", "asc")
  //   //     .start(after: lastVisible)
  //   //     .limit(25);

  //   //   // console.log(next);

  //   //   next.get().then((querySnapshot) => {

  //   //     lastVisible
  //   //     querySnapshot.forEach((doc) => {
  //   //       console.log(doc.data());
  //   //     });
  //   //   });
  //   // });

  //   //** */ call limit of 20

  //   // ** Then append the ID to

  //   // firebase
  //   //   .firestore()
  //   //   .collection("2021-events")
  //   //   .orderBy("id", "asc")
  //   //   .get()
  //   //   .then((querySnapshot) => {
  //   //     console.log(querySnapshot.size);
  //   //     querySnapshot.forEach((doc) => {
  //   //       // console.log(doc.id, " => ", doc.data());
  //   //       eventArray.push(doc.id);
  //   //     });

  //   //     setEvents(eventArray);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  // };

  // const sliceIntoChuncks = (arr, chunkSize) => {
  //   const res = [];
  //   for (let i = 0; i < arr.length; i += chunkSize) {
  //     const chunk = arr.slice(i, i + chunkSize);
  //     res.push(chunk);
  //   }
  //   return res;
  // };

  // let chunkRandom = chunk(events, 24);

  // let chunkLength = chunk(events, 24).length - 1;

  // console.log(chunkLength);

  // function generateRandomInteger(min, max) {
  //   return Math.floor(min + Math.random() * (max + 1 - min));
  // }

  // console.log(chunkRandom[generateRandomInteger(0, chunkLength)]);

  // console.log(chunk(events, 10));

  function chunk(array, size) {
    const chunked_arr = [];
    let copied = [...array]; // ES6 destructuring
    const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
    for (let i = 0; i < numOfChild; i++) {
      chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
  }

  // let chunck = events.splice(0, 10);
  // console.log(events);

  const handleClickGetApi = async () => {
    try {
      const data = await firebase
        .firestore()
        .collection("2021-events")
        .doc("17257")
        .get();

      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("finally");
    }

    // firebase
    //   .firestore()
    //   .collection("2021-events")
    //   .doc("17255")
    //   .get()
    //   .then((doc) => {
    //     console.log(doc.data());
    //   });

    // let config = {
    //   method: "get",
    //   url: "https://677900-sb1.suitetalk.api.netsuite.com/services/rest/record/v1/customrecord_trn_event/17489",
    //   headers: {
    //     Authorization:
    //       'OAuth realm="677900_SB1",oauth_consumer_key="72e1d4c3872ede424d43d733196952f053fad6df299663b1a60ce4cbf5f1ed98",oauth_token="799de3a6f38a45fc3a979e7fb6ae8e67be4f8a9932741c645c998ae6d749e49e",oauth_signature_method="HMAC-SHA256",oauth_timestamp="1629819533",oauth_nonce="NT8OPdNaeM3",oauth_version="1.0",oauth_signature="SeBYGKY3KAxqhzFJObBpI1%2FlSppBvFh4zQijxrffVBk%3D"',
    //     Cookie: "NS_ROUTING_VERSION=LAGGING",
    //   },
    // };
    // axios(config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const cronSchedule = async () => {
    try {
      const getLastVisiable = await firebase
        .firestore()
        .collection("2021")
        .doc("lastVisible")
        .get();

      let lastVisible = getLastVisiable.data().lastEventQuery;

      if (lastVisible) {
        // start of if
        let querySnapshot = await firebase
          .firestore()
          .collection("2021-events")
          .orderBy("id", "desc")
          .startAfter(lastVisible)
          .limit(40)
          .get();

        if (querySnapshot.size !== 0) {
          let newLastVisible =
            querySnapshot.docs[querySnapshot.docs.length - 1].id;

          // udpate lastVisible
          let updateLastVisible = await firebase
            .firestore()
            .collection("2021")
            .doc("lastVisible")
            .update({
              lastEventQuery: newLastVisible,
            });

          const updateEventLoop = await Promise.all(
            querySnapshot.forEach(async (doc) => {
              let eventId = doc.id;

              // send api
              let res = await axios.get(
                `https://us-central1-hrs-events-5a2ea.cloudfunctions.net/app/api/v1/event/${eventId}`
              );

              // return data from api
              let returnEventData = res.data;

              // update database
              let overWriteEvent = await firebase
                .firestore()
                .collection("2021-events")
                .doc(eventId)
                .set(
                  {
                    custrecord_te_class_name:
                      returnEventData.custrecord_te_class_name.refName,
                    custrecord_te_class_sku:
                      returnEventData.custrecord_te_class_sku.refName,
                    custrecord_te_class_type:
                      returnEventData.custrecord_te_class_type.refName,
                    custrecord_te_classroom:
                      returnEventData.custrecord_te_classroom.refName,
                    id: eventId,
                    custrecord_te_closed_class:
                      returnEventData.custrecord_te_closed_class,
                    custrecord_te_confstudents:
                      returnEventData.custrecord_te_confstudents,
                    custrecord_te_end_date:
                      returnEventData.custrecord_te_end_date,
                    custrecord_te_end_time:
                      returnEventData.custrecord_te_end_time,
                    custrecord_te_location:
                      returnEventData.custrecord_te_location.refName,
                    custrecord_te_maxstudents:
                      returnEventData.custrecord_te_maxstudents,
                    custrecord_te_regstudents:
                      returnEventData.custrecord_te_regstudents,
                    custrecord_te_start_date:
                      returnEventData.custrecord_te_start_date,
                    custrecord_te_start_time:
                      returnEventData.custrecord_te_start_time,
                    custrecord_te_status:
                      returnEventData.custrecord_te_status.refName,
                    custrecord_te_timezone:
                      returnEventData.custrecord_te_timezone.refName,
                    custrecord_te_waitstudents:
                      returnEventData.custrecord_te_waitstudents,
                    isInactive: returnEventData.isInactive,
                    custrecord_te_website_notes:
                      returnEventData.custrecord_te_website_notes
                        ? returnEventData.custrecord_te_website_notes
                        : null,
                    custrecord_te_custom_detail:
                      returnEventData.custrecord_te_custom_detail
                        ? returnEventData.custrecord_te_custom_detail
                        : null,
                    month: getEventMonthToLowerString(
                      returnEventData.custrecord_te_start_date
                    ),
                    name: returnEventData.name,
                    year: getEventYearToString(
                      returnEventData.custrecord_te_start_date
                    ),
                  },
                  { merge: true }
                );
              console.log(`${eventId} Updated`);
              // save last
            })
          );

          // update
        } else {
          // udpate lastVisible
          let updateLastVisible = await firebase
            .firestore()
            .collection("2021")
            .doc("lastVisible")
            .update({
              lastEventQuery: null,
            });
          // else set lastVisible to null
          return null;
        }

        // end of if
      } else {
        // start of else
        let querySnapshot = await firebase
          .firestore()
          .collection("2021-events")
          .orderBy("id", "desc")
          .limit(40)
          .get();
        // new lastQuery Id
        let newLastVisible =
          querySnapshot.docs[querySnapshot.docs.length - 1].id;

        // udpate lastVisible
        let updateLastVisible = await firebase
          .firestore()
          .collection("2021")
          .doc("lastVisible")
          .update({
            lastEventQuery: newLastVisible,
          });

        const updateEventLoop = await Promise.all(
          querySnapshot.forEach(async (doc) => {
            let eventId = doc.id;

            // send api
            let res = await axios.get(
              `https://us-central1-hrs-events-5a2ea.cloudfunctions.net/app/api/v1/event/${eventId}`
            );

            // return data from api
            let returnEventData = res.data;

            // update database
            let overWriteEvent = await firebase
              .firestore()
              .collection("2021-events")
              .doc(eventId)
              .set(
                {
                  custrecord_te_class_name:
                    returnEventData.custrecord_te_class_name.refName,
                  custrecord_te_class_sku:
                    returnEventData.custrecord_te_class_sku.refName,
                  custrecord_te_class_type:
                    returnEventData.custrecord_te_class_type.refName,
                  custrecord_te_classroom:
                    returnEventData.custrecord_te_classroom.refName,
                  id: eventId,
                  custrecord_te_closed_class:
                    returnEventData.custrecord_te_closed_class,
                  custrecord_te_confstudents:
                    returnEventData.custrecord_te_confstudents,
                  custrecord_te_end_date:
                    returnEventData.custrecord_te_end_date,
                  custrecord_te_end_time:
                    returnEventData.custrecord_te_end_time,
                  custrecord_te_location:
                    returnEventData.custrecord_te_location.refName,
                  custrecord_te_maxstudents:
                    returnEventData.custrecord_te_maxstudents,
                  custrecord_te_regstudents:
                    returnEventData.custrecord_te_regstudents,
                  custrecord_te_start_date:
                    returnEventData.custrecord_te_start_date,
                  custrecord_te_start_time:
                    returnEventData.custrecord_te_start_time,
                  custrecord_te_status:
                    returnEventData.custrecord_te_status.refName,
                  custrecord_te_timezone:
                    returnEventData.custrecord_te_timezone.refName,
                  custrecord_te_waitstudents:
                    returnEventData.custrecord_te_waitstudents,
                  isInactive: returnEventData.isInactive,
                  custrecord_te_website_notes:
                    returnEventData.custrecord_te_website_notes
                      ? returnEventData.custrecord_te_website_notes
                      : null,
                  custrecord_te_custom_detail:
                    returnEventData.custrecord_te_custom_detail
                      ? returnEventData.custrecord_te_custom_detail
                      : null,
                  month: getEventMonthToLowerString(
                    returnEventData.custrecord_te_start_date
                  ),
                  name: returnEventData.name,
                  year: getEventYearToString(
                    returnEventData.custrecord_te_start_date
                  ),
                },
                { merge: true }
              );
            console.log(`${eventId} Updated`);
            // save last
          })
        );
        // end of else
      }
    } catch (error) {
      console.log(error);
    }

    console.log("This will be run every 5 minutes!");
    return null;
  };

  const test = async () => {
    let querySnapshot = await firebase
      .firestore()
      .collection("2021-events")
      .orderBy("id", "desc")
      .limit(40)
      .get();

    console.log(querySnapshot);

    if (querySnapshot.size > 0) {
      querySnapshot.forEach((data) => {
        console.log(data.data());
        // return null
      });
    }
  };

  const updateApi = async () => {
    let eventId = "17319";

    // do an api call
    try {
      // let dosomething = await firebase
      //   .firestore()
      //   .collection("2021-events")
      //   .doc("17309")
      //   .get();

      // console.log(dosomething.data());

      let dosomething = await firebase
        .firestore()
        .collection("2021")
        .doc("august")
        .set(
          {
            events: firebase.firestore.FieldValue.arrayUnion({
              custrecord_te_class_name:
                "SWX: SOLIDWORKS Mold Design Essentials Course",
              custrecord_te_class_type: "Online",
              custrecord_te_closed_class: false,
              custrecord_te_start_date: "2021-09-30",
              custrecord_te_status: "Scheduled12",
              id: "17309",
              isInactive: false,
            }),
          },
          { merge: true }
        );

      console.log(dosomething);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1 style={{ textAlign: "center" }}>Dashboard</h1>
        {/* <BrandButton
          title="Firebase Test"
          bgColorPrimary="true"
          onClick={updateApi}
        /> */}
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={3}>
          <h5>HRS-EVENTS</h5>
          <p style={{ fontSize: 16 }}>Add/Delete HRS events</p>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={3}>
          <h5>UPDATE/ADD TRAINING EVENTS</h5>
          <p style={{ fontSize: 16 }}>Add or update training events</p>
          <p style={{ fontSize: 16 }}>
            Use Case:
            <ul>
              <li>Adding a new class</li>
              <li>
                A training event was updated in Netsuite (Manually Update
                without waiting for the 24 hours turnaround)
              </li>
              <li>
                Someone registered and I want to update the number website right
                away
              </li>
              <li>
                A training event got canceled and I do not want it on the
                website ASAP
              </li>
              <li>
                A training event got canceled and I do not want it on the
                website ASAP
              </li>
            </ul>
          </p>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={3}>
          <h5>IMPORT TRAINING EVENTS</h5>
          <p style={{ fontSize: 16 }}>
            This feature import new training events for the month and year
            selected
          </p>
          <p style={{ fontSize: 16 }}>
            This feature also overwrite training events for the month and year
            selected with new data from Netsuite.
          </p>
          <p style={{ fontSize: 16 }}>
            Use Case:
            <ul>
              <li>
                I updated 14 training events in NetSuite for the month of
                (month) and I do not want to wait for the 24 hours turnaround
              </li>
              <li>
                All classes for March 2025 has been scheduled. Lets put it on
                the website
              </li>
            </ul>
          </p>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={3}>
          <h5>COURSE SCHEDULE</h5>
          <p style={{ fontSize: 16 }}>View Course Schedule</p>
        </Paper>
      </Grid>

      {/* <BrandButton title="Test" bgColorPrimary="true" onClick={cronSchedule} /> */}

      {/* {isUserLoggedIn && (
          <Grid item xs={12} md={4}>
            <Notifications />
          </Grid>
        )} */}
    </Grid>
  );
};

export default Dashboard;
