import { createSlice } from "@reduxjs/toolkit";
import { popupAlert } from "../../utils/popupAlert";

const initialState = {
  isLoading: true,
};

export const slice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const loadingSlice = slice.actions;

export const changeLoading = (isLoading) => async (dispatch) => {
  try {
    dispatch(slice.actions.loading(isLoading));
  } catch (error) {
    popupAlert("error", "Error", error.message);
  }
};

export default slice.reducer;
