import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../config/fbConfig";
// reducers
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../redux/reducers/loginReducers";
// components
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { Container } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import AdminBrandButton from "../button/AdminBrandButton";
// Form
import { useFormik } from "formik";
// utils
import { popupAlert } from "../../utils/popupAlert";
import { userSchema } from "../../utils/validationSchema";

// assets
import signInLogo from "../../assets/sign-in-logo.png";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
    height: "100vh",
    marginTop: "10vh",
  },
  form: {
    padding: 20,
  },
  image: {
    padding: 20,
  },
}));

const SignIn = ({ props }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  // formik form submission
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      const email = values.email.trim().toLowerCase();
      const password = values.password.trim();
      setIsLoading(true);

      firebase
        .auth()
        .setPersistence("session")
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((res) => {
              save(res);
            })
            .catch((error) => {
              popupAlert("error", "Error", error.message);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          // var errorCode = error.code;
          var errorMessage = error.message;
          popupAlert("error", "Error", errorMessage);
        });
    },
  });

  const save = async (user) => {
    let userInfo = {
      uid: user.user.uid,
      email: user.user.email,
      login: true,
      refreshToken: user.user.refreshToken,
    };

    try {
      dispatch(
        isLoggedIn(
          userInfo.uid,
          userInfo.email,
          userInfo.login,
          userInfo.refreshToken
        )
      );

      history.push("/");
    } catch (error) {
      popupAlert("error", "Error", error.message);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Paper elevation={3}>
        <img src={signInLogo} width="100%" className={classes.image} />
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          {!isLoading && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <AdminBrandButton
                title="Sign In"
                bgColorPrimary={true}
                // onClick={() => handleClick()}
                type="submit"
                style={{ width: "100%", margin: "0px " }}
              />
            </div>
          )}
        </form>
        {isLoading && <LinearProgress />}
      </Paper>
    </Container>
  );
};

export default SignIn;
