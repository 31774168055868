import React, { useState } from "react";
import moment from "moment";
import firebase from "../../config/fbConfig";

//components
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";

// Form
import { useFormik } from "formik";
import { addHrsEventSchema } from "../../utils/validationSchema";
import { Button } from "@material-ui/core";
import { getEventYearToString } from "../../utils/dateManipulate";
import { popupAlert } from "../../utils/popupAlert";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    // maxWidth: "900px",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    minWidth: "300px",
    marginTop: 20,
  },
}));

const AddEventModal = ({ visible, handleAdd, closeModal }) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  // form state
  const [isEventActive, setIsEventActive] = useState(true);
  const [isEventVirtual, setIsEventVirtual] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      eventName: "",
      imageUrl: "",
      location: "",
      registrationLink: "",
      timeZone: "",
      eventStartDateAndTime: moment().format("YYYY-MM-DDTHH:mm"),
      eventEndDateAndTime: moment().format("YYYY-MM-DDTHH:mm"),
    },
    validationSchema: addHrsEventSchema,
    onSubmit: async (values, { resetForm }) => {
      setisSubmit(true);

      const eventName = values.eventName.trim();
      const imageUrl = values.imageUrl.trim();
      const location =
        values.location.length > 0 ? values.location.trim() : null;
      const registrationLink = values.registrationLink.trim();
      const timeZone = values.timeZone.trim();
      const eventStartDate = values.eventStartDateAndTime;
      const eventEndDate = values.eventEndDateAndTime;
      const isActive = isEventActive;
      const isVirtual = isEventVirtual;

      const yearCollection = getEventYearToString(eventStartDate);

      try {
        let addDataToEvent = await firebase
          .firestore()
          .collection(`${yearCollection}-hrs-events`)
          .add({
            eventName,
            imageUrl,
            location,
            registrationLink,
            timeZone,
            eventStartDate,
            eventEndDate,
            isActive,
            isVirtual,
          });
        // save to db

        popupAlert("success", "Success", "Event Added");
      } catch (error) {
        popupAlert("error", "Error", "Unable to save, please try again later");
      } finally {
        //  reset form
        resetForm({ values: "" });
        setisSubmit(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    },
  });

  const handleIsActive = (event) => {
    setIsEventActive(event.target.value);
  };

  const handleIsVirtual = (event) => {
    setIsEventVirtual(event.target.value);
  };

  return (
    <Modal
      open={visible}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Add Event</h2>
        <form onSubmit={formik.handleSubmit} className={classes.container}>
          <TextField
            fullWidth
            id="eventName"
            name="eventName"
            label="Event Name"
            value={formik.values.eventName}
            onChange={formik.handleChange}
            error={formik.touched.eventName && Boolean(formik.errors.eventName)}
            helperText={formik.touched.eventName && formik.errors.eventName}
          />
          <TextField
            fullWidth
            id="eventStartDateAndTime"
            label="Start Date and Time"
            type="datetime-local"
            onChange={formik.handleChange}
            defaultValue={formik.values.eventStartDateAndTime}
            error={
              formik.touched.eventStartDateAndTime &&
              Boolean(formik.errors.eventStartDateAndTime)
            }
            helperText={
              formik.touched.eventStartDateAndTime &&
              formik.errors.eventStartDateAndTime
            }
            // defaultValue={moment().format("YYYY-MM-DDTHH:MM")}
          />
          <TextField
            fullWidth
            id="eventEndDateAndTime"
            label="End Date and Time"
            type="datetime-local"
            onChange={formik.handleChange}
            defaultValue={formik.values.eventEndDateAndTime}
            error={
              formik.touched.eventEndDateAndTime &&
              Boolean(formik.errors.eventEndDateAndTime)
            }
            helperText={
              formik.touched.eventEndDateAndTime &&
              formik.errors.eventEndDateAndTime
            }
            // defaultValue={moment().format("YYYY-MM-DDTHH:MM")}
          />
          <TextField
            fullWidth
            id="timeZone"
            name="timeZone"
            label="Timezone"
            value={formik.values.timeZone}
            onChange={formik.handleChange}
            error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
            helperText={formik.touched.timeZone && formik.errors.timeZone}
          />
          <TextField
            fullWidth
            id="imageUrl"
            name="imageUrl"
            label="Image URL"
            value={formik.values.imageUrl}
            onChange={formik.handleChange}
            error={formik.touched.imageUrl && Boolean(formik.errors.imageUrl)}
            helperText={formik.touched.imageUrl && formik.errors.imageUrl}
          />
          <TextField
            fullWidth
            id="registrationLink"
            name="registrationLink"
            label="Registration Link"
            value={formik.values.registrationLink}
            onChange={formik.handleChange}
            error={
              formik.touched.registrationLink &&
              Boolean(formik.errors.registrationLink)
            }
            helperText={
              formik.touched.registrationLink && formik.errors.registrationLink
            }
          />
          <TextField
            fullWidth
            id="location"
            name="location"
            label="Location (Optional)"
            value={formik.values.location}
            onChange={formik.handleChange}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="isEventActive">Display on frontend?</InputLabel>
            <Select
              labelId="isEventActive"
              id="isEventActive"
              value={isEventActive}
              onChange={handleIsActive}
              label="Hide Registration Link?"
            >
              <MenuItem value={true}>Display</MenuItem>
              <MenuItem value={false}>Hide</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="isEventVirtual">Virtual Event?</InputLabel>
            <Select
              labelId="isEventVirtual"
              id="isEventVirtual"
              value={isEventVirtual}
              onChange={handleIsVirtual}
              label="Virtual Event?"
            >
              <MenuItem value={false}>Has Location</MenuItem>
              <MenuItem value={true}>Virtual</MenuItem>
            </Select>
          </FormControl>

          {!isSubmit && (
            <div style={{ marginTop: "20px", width: "100%" }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </div>
          )}
        </form>
        {isSubmit && <LinearProgress />}
      </div>
    </Modal>
  );
};

export default AddEventModal;
