import React from "react";

// css
import "./buttonStyles.css";

const BrandButton = ({
  title,
  onClick,
  bgColorPrimary,
  style,
  type = "button",
}) => {
  if (!bgColorPrimary) {
    return (
      <button
        className="wp-button wp-button-large wp-button-brand-primary wp-button-rounded color-primary-hover"
        onClick={onClick}
        style={style}
        type={type}
      >
        {title}
      </button>
    );
  }
  return (
    <button
      className="wp-button wp-button-large wp-button-brand-primary wp-button-rounded color-primary-hover"
      onClick={onClick}
      style={style}
      type={type}
    >
      {title}
    </button>
  );
};

export default BrandButton;
