import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyAjXx90kU7WjKKpGKQW8Gi1I-5Jip2FSgo",
  authDomain: "hrs-events-5a2ea.firebaseapp.com",
  projectId: "hrs-events-5a2ea",
  storageBucket: "hrs-events-5a2ea.appspot.com",
  messagingSenderId: "242000479310",
  appId: "1:242000479310:web:b03c27d54683e5c6bf6f1f",
  measurementId: "G-P1LQZ8DQZD",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });

export default firebase;
